import { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button, TextField, Typography } from '@mui/material';
import { useStakeUnstakeRadioGroup } from 'components';
import { InfoIcon } from 'components/Icon/components';
import { useShallowSelector } from 'hooks';
import { useWalletConnectorContext } from 'services';
import { stake, unstake } from 'store/staking/actions';
import actionTypes from 'store/staking/actionTypes';
import uiSelector from 'store/ui/selectors';
import userSelector from 'store/user/selectors';
import { RequestStatus, State, UserState } from 'types';
import { validateOnlyNumbers } from 'utils';
import { flexHelper } from 'utils/flexHelper';

export const StakeUnstake: FC = () => {
  const dispatch = useDispatch();
  const { walletService } = useWalletConnectorContext();

  const { [actionTypes.STAKE]: stakeRequestStatus, [actionTypes.UNSTAKE]: unstakeRequestStatus } = useShallowSelector(
    uiSelector.getUI,
  );

  const { StakeUnstakeRadioGroup, isStakeSelected } = useStakeUnstakeRadioGroup();

  const [stakeUnstakeAmount, setStakeUnstakeAmount] = useState('');
  const { stakingInfo, tokenBalance, address } = useShallowSelector<State, UserState>(userSelector.getUser);

  const stakeUnstakeText = isStakeSelected ? 'Stake' : 'Unstake';
  const isStakeBtnDisabled = stakeUnstakeAmount === '' || stakeUnstakeAmount === '0';
  const isUnstakeBtnDisabled = stakingInfo.staked === '0' || stakeUnstakeAmount === '' || stakeUnstakeAmount === '0';

  const maxAmount = useMemo(
    () => (isStakeSelected ? tokenBalance : stakingInfo.staked),
    [isStakeSelected, stakingInfo.staked, tokenBalance],
  );

  const handleAmountChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const { value } = event.target;
      if (+value > +maxAmount) {
        return;
      }
      if (validateOnlyNumbers(value)) {
        setStakeUnstakeAmount(value);
      }
    },
    [maxAmount],
  );

  const handleMax = useCallback(() => {
    setStakeUnstakeAmount(maxAmount);
  }, [maxAmount]);

  const handleStakeUnstake = () => {
    if (isStakeSelected && isStakeBtnDisabled) {
      return;
    }
    if (!isStakeSelected && isUnstakeBtnDisabled) {
      return;
    }
    if (isStakeSelected) {
      dispatch(
        stake({
          web3Provider: walletService.Web3(),
          amount: stakeUnstakeAmount,
        }),
      );
    } else {
      dispatch(
        unstake({
          web3Provider: walletService.Web3(),
          amount: stakeUnstakeAmount,
        }),
      );
    }
  };

  useEffect(() => {
    if (isStakeSelected || !isStakeSelected) {
      setStakeUnstakeAmount('');
    }
  }, [isStakeSelected]);
  useEffect(() => {
    if (stakeRequestStatus === RequestStatus.SUCCESS) {
      setStakeUnstakeAmount('');
      dispatch({ type: `${actionTypes.STAKE}_RESET` });
    }
  }, [dispatch, stakeRequestStatus]);
  useEffect(() => {
    if (unstakeRequestStatus === RequestStatus.SUCCESS) {
      setStakeUnstakeAmount('');
      dispatch({ type: `${actionTypes.UNSTAKE}_RESET` });
    }
  }, [dispatch, unstakeRequestStatus]);

  return (
    <Box
      sx={{
        textAlign: 'left',
      }}
    >
      <StakeUnstakeRadioGroup />
      <Typography variant="subtitle2" mt="28px" mb={0.5}>
        Amount
      </Typography>
      <TextField
        disabled={!address.length}
        placeholder="0.00"
        value={stakeUnstakeAmount}
        onChange={handleAmountChange}
        fullWidth
        InputProps={{
          endAdornment: (
            <Button onClick={handleMax} variant="contained" size="small">
              MAX
            </Button>
          ),
        }}
      />
      <Box sx={{ mt: 2, ...flexHelper('flex-start', 'flex-start') }}>
        <InfoIcon sx={{ mr: '11px', color: 'transparent' }} />
        <Typography variant="subtitle2">
          Please note that 3% fee is taken when you stake, and 5% fee is taken when you unstake. These fees are further
          distributed as staking rewards.
        </Typography>
      </Box>
      <Button
        sx={{ mt: 4.5 }}
        variant="contained"
        color={isStakeSelected ? 'secondary' : 'primary'}
        fullWidth
        disabled={address.length === 0}
        onClick={handleStakeUnstake}
      >
        {stakeUnstakeText}
      </Button>
    </Box>
  );
};
