import { FC, useCallback, useRef } from 'react';
import { Box, Button, Grid, Popover, Typography } from '@mui/material';
import { ArrowIcon, BulbIcon } from 'components/Icon/components';
import { useModal } from 'hooks';
import { flexHelper } from 'utils/flexHelper';

interface ConnectPopoverProps {
  showPopover: boolean;
  hideTip: () => void;
}

export const TipPopover: FC<ConnectPopoverProps> = ({ showPopover, hideTip }) => {
  const popoverRef = useRef(null);
  const [isPopoverVisible, , onClosePopover] = useModal(showPopover);

  const beforeClose = useCallback(() => {
    hideTip();
    onClosePopover();
  }, [hideTip, onClosePopover]);

  return (
    <>
      <Grid
        item
        sx={{
          ml: 'auto',
        }}
      >
        <Box ref={popoverRef} />
      </Grid>
      <Popover
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isPopoverVisible}
        anchorEl={popoverRef.current}
        onClose={beforeClose}
        sx={{
          '& .MuiPopover-paper': {
            mt: { xs: 15, sm: 11 },
            ml: { xs: 0, sm: 3 },
            p: 3,
            width: { xs: '100%', sm: 400 },
            position: 'relative',
            overflow: 'visible',
          },
        }}
      >
        <Box sx={{ position: 'absolute', right: -165, top: -65 }}>
          <ArrowIcon />
        </Box>
        <Box
          sx={{
            ...flexHelper('flex-start', 'flex-start'),
          }}
        >
          <BulbIcon sx={{ mr: '15px' }} />
          <Box>
            <Typography variant="h4" lineHeight="32px">
              Please choose your crypto wallet to use the full functionality of the dKuma Breeder
            </Typography>
            <Button
              variant="text"
              onClick={beforeClose}
              sx={{
                color: '#61646D',
                textDecorationLine: 'underline',
                fontSize: '14px',
                fontWeight: 600,
                mt: '11px',
                p: 0,
                minWidth: 'auto',
              }}
            >
              Skip
            </Button>
          </Box>
        </Box>
      </Popover>
    </>
  );
};
