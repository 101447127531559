import { fork } from 'redux-saga/effects';

import claim from './claim';
import getStakingData from './getStakingData';
import stake from './stake';
import unstake from './unstake';

export default function* stakingSagas() {
  yield fork(getStakingData);
  yield fork(stake);
  yield fork(unstake);
  yield fork(claim);
}
