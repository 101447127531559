import { FC, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import BigNumber from 'bignumber.js';
import { COLOR_GREEN, COLOR_ORANGE, COLOR_WHITE } from 'theme/variables';
import { getFormattedCounterDate } from 'utils';
import useTimeLeft from 'utils/useTimeLeft';

export interface CountdownProps {
  deadline: number;
  countdownFinish: boolean;
  updateState: () => void;
}

export interface ITimeLeft {
  days: number | string;
  hours: number | string;
  minutes: number | string;
  seconds: number | string;
}

export const Countdown: FC<CountdownProps> = ({ deadline, countdownFinish, updateState }) => {
  const timeLeft = useTimeLeft(new BigNumber(deadline).multipliedBy(1000).toString());

  const formattedTimeLeft = useMemo(() => {
    if (!timeLeft) return { days: '0', hours: '0', minutes: '0', seconds: '0' };
    return timeLeft;
  }, [timeLeft]);

  return (
    <Box width="100%" display="flex" flexDirection="row">
      <Box mr={{ xs: 2, md: 6 }}>
        <Typography variant="h1" color={countdownFinish ? COLOR_GREEN : COLOR_ORANGE}>
          {getFormattedCounterDate(formattedTimeLeft.days)}
        </Typography>
        <Typography variant="body2" sx={{ fontSize: { xs: '12px', md: '16px' } }} color={COLOR_WHITE}>
          days
        </Typography>
      </Box>
      <Box mr={1}>
        <Typography variant="h1" color={countdownFinish ? COLOR_GREEN : COLOR_ORANGE}>
          {getFormattedCounterDate(formattedTimeLeft.hours)}
        </Typography>
        <Typography ml={1} variant="body2" sx={{ fontSize: { xs: '12px', md: '16px' } }} color={COLOR_WHITE}>
          hours
        </Typography>
      </Box>
      <Box mr={{ xs: 1, md: 2 }}>
        <Typography variant="h1" color={countdownFinish ? COLOR_GREEN : COLOR_ORANGE}>
          :
        </Typography>
      </Box>
      <Box mr={1}>
        <Typography variant="h1" color={countdownFinish ? COLOR_GREEN : COLOR_ORANGE}>
          {getFormattedCounterDate(formattedTimeLeft.minutes)}
        </Typography>
        <Typography ml={1} variant="body2" sx={{ fontSize: { xs: '12px', md: '16px' } }} color={COLOR_WHITE}>
          min
        </Typography>
      </Box>
      <Box mr={{ xs: 1, md: 2 }}>
        <Typography variant="h1" color={countdownFinish ? COLOR_GREEN : COLOR_ORANGE}>
          :
        </Typography>
      </Box>
      <Box>
        <Typography variant="h1" color={countdownFinish ? COLOR_GREEN : COLOR_ORANGE}>
          {getFormattedCounterDate(formattedTimeLeft.seconds)}
        </Typography>
        <Typography ml={1} variant="body2" sx={{ fontSize: { xs: '12px', md: '16px' } }} color={COLOR_WHITE}>
          sec
        </Typography>
      </Box>
    </Box>
  );
};
