import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const WalletIcon: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 21 20"
    sx={{
      ...sx,
      width: '21px',
      height: '20px',
    }}
  >
    <path
      d="M17.2112 5.83333V4.16667C17.2112 3.2475 16.4637 2.5 15.5446 2.5H4.71124C3.33291 2.5 2.21124 3.62167 2.21124 5V15C2.21124 16.8342 3.70624 17.5 4.71124 17.5H17.2112C18.1304 17.5 18.8779 16.7525 18.8779 15.8333V7.5C18.8779 6.58083 18.1304 5.83333 17.2112 5.83333ZM15.5446 13.3333H13.8779V10H15.5446V13.3333ZM4.71124 5.83333C4.49668 5.82374 4.29408 5.73174 4.14564 5.57651C3.9972 5.42129 3.91435 5.21478 3.91435 5C3.91435 4.78522 3.9972 4.57871 4.14564 4.42349C4.29408 4.26826 4.49668 4.17626 4.71124 4.16667H15.5446V5.83333H4.71124Z"
      fill="#FF8400"
      style={{ transition: 'fill 0.3s ease-out' }}
    />
  </SvgIcon>
);
