import { useCallback, useMemo, useState } from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { styled } from '@mui/material/styles';
import { COLOR_GREEN, COLOR_WHITE } from 'theme/variables';
import { flexHelper } from 'utils/flexHelper';

export enum RadioStates {
  stake = 'stake',
  unstake = 'unstake',
}

const RadioIcon = styled('span')(() => ({
  borderRadius: '50%',
  width: 24,
  height: 24,
  background: 'rgba(255, 255, 255, 0.05)',

  border: '1px solid rgba(255, 255, 255, 0.2)',
  'input:hover ~ &': {
    borderColor: COLOR_GREEN,
  },
}));

const RadioCheckedIcon = styled(RadioIcon)({
  backgroundColor: COLOR_GREEN,
  ...flexHelper(),
  '&:before': {
    display: 'block',
    width: 12,
    height: 12,
    borderRadius: '50%',
    backgroundColor: COLOR_WHITE,
    content: '""',
  },
});

export const useStakeUnstakeRadioGroup = () => {
  const [radioState, setRadioState] = useState<RadioStates>(RadioStates.stake);
  const isStakeSelected = radioState === RadioStates.stake;
  // const isStakeSelected = false;

  const radioHandler = useCallback((event) => {
    setRadioState(event.target.value);
  }, []);

  const StakeUnstakeRadioGroup = useMemo(
    () => () =>
      (
        <RadioGroup row value={radioState} onChange={radioHandler}>
          <FormControlLabel
            sx={{ marginRight: 4 }}
            value="stake"
            control={<Radio disableRipple color="default" checkedIcon={<RadioCheckedIcon />} icon={<RadioIcon />} />}
            label="Stake"
          />
          <FormControlLabel
            value="unstake"
            control={<Radio disableRipple color="default" checkedIcon={<RadioCheckedIcon />} icon={<RadioIcon />} />}
            label="Unstake"
          />
        </RadioGroup>
      ),
    [radioHandler, radioState],
  );

  return {
    StakeUnstakeRadioGroup,
    isStakeSelected,
    radioState,
    setRadioState,
  };
};
