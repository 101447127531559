import { FC } from 'react';
import { Modal } from 'components';

import { StakeUnstake } from '../../../StakeUnstake';

interface StakeUnstakeModalProps {
  open: boolean;
  onClose: () => void;
}

export const StakeUnstakeModal: FC<StakeUnstakeModalProps> = ({ open, onClose }) => {
  return (
    <Modal open={open} onClose={onClose} modalMaxWidth={347} customTitle="Staking">
      <StakeUnstake />
    </Modal>
  );
};
