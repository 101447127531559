// ===== Colors
export const COLOR_BLUE_DARK = '#141823';
export const COLOR_BLACK_DARK = '#0D0F17';
export const COLOR_BLACK_LIGHT = '#0D1218';
export const COLOR_WHITE = '#E6E5E5';
export const COLOR_GREY_100 = '#BBB9B9';
export const COLOR_GREY_200 = '#737C96';
export const COLOR_GREY_300 = '#3D414D';
export const COLOR_GREY_400 = '#798094';
export const COLOR_GREY_500 = '#7B7B7B';
export const COLOR_ORANGE = '#FF8400';
export const COLOR_ORANGE_DARK = '#582817';
export const COLOR_BLUE = '#0284C5';
export const COLOR_PURPLE = '#7E22CE';
export const COLOR_GREEN = '#00AA2D';
export const COLOR_GREEN_DARK = '#0E3619';
export const COLOR_BLACK = '#0D0F17';

// ===== Border-Radius
export const BORDER_RADIUS_LARGE = '16px';
export const BORDER_RADIUS_DEFAULT = '12px';
export const BORDER_RADIUS_SMALL = '8px';

// ===== Transitions
export const TRANSITION_DEFAULT_TIME = 'all 0.25s ease-in-out';
