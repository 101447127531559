import { ComponentsOverrides, ComponentsProps } from '@mui/material';

export const getMuiRadioOverrides = (): ComponentsOverrides['MuiRadio'] => ({
  root: {},
});

export const getMuiRadioDefaultProps = (): ComponentsProps['MuiRadio'] => ({
  color: 'default',
  disableRipple: true,
});
