import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const WebIcon: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 40 40"
    sx={{
      ...sx,
      width: '40px',
      height: '40px',
    }}
  >
    <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" fill="#141823" />
    <path
      d="M11.0549 19H13C14.1046 19 15 19.8954 15 21V22C15 23.1046 15.8954 24 17 24C18.1046 24 19 24.8954 19 26V28.9451M16 11.9355V13.5C16 14.8807 17.1193 16 18.5 16H19C20.1046 16 21 16.8954 21 18C21 19.1046 21.8954 20 23 20C24.1046 20 25 19.1046 25 18C25 16.8954 25.8954 16 27 16L28.0645 16M23 28.4879V26C23 24.8954 23.8954 24 25 24H28.0645M29 20C29 24.9706 24.9706 29 20 29C15.0294 29 11 24.9706 11 20C11 15.0294 15.0294 11 20 11C24.9706 11 29 15.0294 29 20Z"
      stroke="#3D414D"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="#3D414D" />
  </SvgIcon>
);
