import { Chains, IChainType, IConnectWallet, IContracts } from 'types';
import { AbiItem } from 'web3-utils';

import { erc20Abi, stakingAbi, uniswapRouterAbi } from './abi';

const WALLETCONNECT_PROJECT_ID = process.env.REACT_APP_WALLETCONNECT_PROJECT_ID || '';

export const chains: {
  [key in Chains]: {
    [key: string]: {
      name: string;
      nativeCurrency: {
        name: string;
        symbol: string;
        decimals: number;
      };
      rpc: string;
      blockExplorerUrl: string;
      chainId: number;
      provider: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [key: string]: any;
      };

      img?: string;
    };
  };
} = {
  [Chains.Ethereum]: {
    mainnet: {
      name: 'Ethereum',
      chainId: 1,
      nativeCurrency: {
        name: 'ETH',
        symbol: 'ETH',
        decimals: 18,
      },
      rpc: 'https://mainnet.infura.io/v3/4d2ebfa160d54fddb43d5f09768914b6',
      blockExplorerUrl: 'https://etherscan.io/',
      provider: {
        MetaMask: { name: 'MetaMask' },
        WalletConnect: {
          name: 'WalletConnect',
          useProvider: 'walletConnectV2',
          provider: {
            rpc: {
              rpc: {
                1: 'https://mainnet.infura.io/v3/4d2ebfa160d54fddb43d5f09768914b6',
              },
              chainId: 1,
            },
            walletConnectV2: {
              projectId: WALLETCONNECT_PROJECT_ID,
              chains: [1],
              showQrModal: true,
              metadata: {
                description: 'Stake dKUMA and eard USDC rewards',
                url: 'https://dbreeder.kumatokens.com',
                icons: ['https://dbreeder.kumatokens.com/dkuma.png'],
                name: 'dKuma Breeder',
              },
            },
          },
        },
      },
    },
    testnet: {
      name: 'Sepolia',
      chainId: 11155111,
      nativeCurrency: {
        name: 'sETH',
        symbol: 'sETH',
        decimals: 18,
      },
      rpc: 'https://eth-sepolia.g.alchemy.com/v2/-UqUTjPeprV-ATySQiAQvwy-RospKTCX',
      blockExplorerUrl: 'https://sepolia.etherscan.io/',
      provider: {
        MetaMask: { name: 'MetaMask' },
        WalletConnect: {
          name: 'WalletConnect',
          useProvider: 'walletConnectV2',
          provider: {
            rpc: {
              rpc: {
                11155111: 'https://eth-sepolia.g.alchemy.com/v2/-UqUTjPeprV-ATySQiAQvwy-RospKTCX',
              },
              chainId: 11155111,
            },
            walletConnectV2: {
              projectId: WALLETCONNECT_PROJECT_ID,
              chains: [11155111],
              showQrModal: true,
              metadata: {
                description: 'Stake dKUMA and eard USDC rewards',
                url: 'https://dbreeder.kumatokens.com',
                icons: ['https://dbreeder.kumatokens.com/dkuma.png'],
                name: 'dKuma Breeder',
              },
            },
          },
        },
      },
      // name: 'Kovan',
      // chainId: 42,
      // nativeCurrency: {
      //   name: 'kETH',
      //   symbol: 'kETH',
      //   decimals: 18,
      // },
      // rpc: 'https://kovan.infura.io/v3/4d2ebfa160d54fddb43d5f09768914b6',
      // blockExplorerUrl: 'https://kovan.etherscan.io/',
      // provider: {
      //   MetaMask: { name: 'MetaMask' },
      //   WalletConnect: {
      //     name: 'WalletConnect',
      //     useProvider: 'rpc',
      //     provider: {
      //       rpc: {
      //         rpc: {
      //           42: 'https://kovan.infura.io/v3/4d2ebfa160d54fddb43d5f09768914b6',
      //         },
      //         chainId: 42,
      //       },
      //     },
      //   },
      // },
    },
  },
};

export const connectWallet = (newChainName: Chains, type: IChainType): IConnectWallet => {
  const chain = chains[newChainName][type];
  return {
    network: {
      chainName: chain.name,
      chainID: chain.chainId,
      nativeCurrency: chain.nativeCurrency,
      rpc: chain.rpc,
      blockExplorerUrl: chain.blockExplorerUrl,
    },
    provider: chain.provider,
    settings: { providerType: true },
  };
};

export enum ContractsNames {
  token,
  usdc,
  weth,
  staking,
  uniswapRouter,
}

export type IContractsNames = keyof typeof ContractsNames;

export const contractsConfig: IContracts = {
  names: Object.keys(ContractsNames),
  decimals: 18,
  contracts: {
    [ContractsNames.token]: {
      testnet: {
        address: {
          // [Chains.Ethereum]: '0x7F961798AFc44C52c264CBf723d92FD9014A0f81',
          [Chains.Ethereum]: '0x13ca7749C796C5c3e8708ef156eF7466a624fDB2',
        },
        abi: erc20Abi as AbiItem[],
      },
      mainnet: {
        address: {
          [Chains.Ethereum]: '0x3f5dd1A1538a4F9f82E543098f01F22480B0A3a8',
          // [Chains.Ethereum]: '0x13ca7749C796C5c3e8708ef156eF7466a624fDB2',
        },
        abi: erc20Abi as AbiItem[],
      },
    },
    [ContractsNames.usdc]: {
      testnet: {
        address: {
          // [Chains.Ethereum]: '0x0D9c9f65A0acb23A889bDe381a41Ea12bd8ED463',
          [Chains.Ethereum]: '0x27803090CF171deD8EF6586105E962D3bb24da15',
        },
        abi: erc20Abi as AbiItem[],
      },
      mainnet: {
        address: {
          [Chains.Ethereum]: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
          // [Chains.Ethereum]: '0x27803090CF171deD8EF6586105E962D3bb24da15',
        },
        abi: erc20Abi as AbiItem[],
      },
    },
    [ContractsNames.weth]: {
      testnet: {
        address: {
          // [Chains.Ethereum]: '0xd0A1E359811322d97991E03f863a0C30C2cF029C',
          [Chains.Ethereum]: '0x7b79995e5f793A07Bc00c21412e50Ecae098E7f9',
        },
        abi: erc20Abi as AbiItem[],
      },
      mainnet: {
        address: {
          [Chains.Ethereum]: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
          // [Chains.Ethereum]: '0x7b79995e5f793A07Bc00c21412e50Ecae098E7f9',
        },
        abi: erc20Abi as AbiItem[],
      },
    },
    [ContractsNames.staking]: {
      testnet: {
        address: {
          // [Chains.Ethereum]: '0xcA952accfA33Fdd9bf5b415DBd8468e5b9c1c8F1',
          [Chains.Ethereum]: '0x02297ecACf86C5C5C9A5B422A5ca3362117eEF8f',
        },
        abi: stakingAbi as AbiItem[],
      },
      mainnet: {
        address: {
          [Chains.Ethereum]: '0x00844af60e061c30bb8cff5d5d1637559ae1b682',
          // [Chains.Ethereum]: '0x4292008aB364d8480511B36942c8D2768113f768',
        },
        abi: stakingAbi as AbiItem[],
      },
    },
    [ContractsNames.uniswapRouter]: {
      testnet: {
        address: {
          // [Chains.Ethereum]: '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
          [Chains.Ethereum]: '0xC532a74256D3Db42D0Bf7a0400fEFDbad7694008',
        },
        abi: uniswapRouterAbi as AbiItem[],
      },
      mainnet: {
        address: {
          [Chains.Ethereum]: '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
          // [Chains.Ethereum]: '0xC532a74256D3Db42D0Bf7a0400fEFDbad7694008',
        },
        abi: uniswapRouterAbi as AbiItem[],
      },
    },
  },
};
