import { select } from 'redux-saga/effects';
import { ContractsNames } from 'services/WalletService/config';
import { error, request, success } from 'store/api/actions';
import userSelector from 'store/user/selectors';
import { call, put, takeLatest } from 'typed-redux-saga';
import { UserState } from 'types';
import { Erc20Abi } from 'types/contracts';
import { fromDecimals, getContractDataByItsName } from 'utils';

import { getTokenBalance } from '../actions';
import actionTypes from '../actionTypes';
import { updateUserState } from '../reducer';

export function* getTokenBalanceSaga({ type, payload: { web3Provider } }: ReturnType<typeof getTokenBalance>) {
  yield put(request(type));
  const { address: userAddress, chainType }: UserState = yield select(userSelector.getUser);
  // console.log('chainType', chainType);
  // console.log('userAddress', userAddress);

  const [tokenAbi, tokenAddress] = getContractDataByItsName(ContractsNames.token, chainType);
  try {
    // console.log('here');
    const tokenContract: Erc20Abi = yield new web3Provider.eth.Contract(tokenAbi, tokenAddress);
    // console.log('tokenContract', tokenContract);

    if (userAddress) {
      // console.log('here3');
      let balance = yield* call(tokenContract.methods.balanceOf(userAddress).call);
      if (typeof balance === 'bigint') balance = balance.toString();
      // console.log('here3.1');
      // console.log('balance', balance);
      let decimals = yield* call(tokenContract.methods.decimals().call);
      if (typeof decimals === 'bigint') decimals = decimals.toString();

      yield put(updateUserState({ tokenBalance: fromDecimals(balance, +decimals) }));
      yield put(success(type));
    }
  } catch (err) {
    // console.log('error');
    // eslint-disable-next-line no-console
    console.error(err);
    yield put(error(type));
  }
  // console.log('here4');
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_TOKEN_BALANCE, getTokenBalanceSaga);
}
