import { FC } from 'react';
import { FacebookShareButton } from 'react-share';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { HappyKuma } from 'assets/img';
import BigNumber from 'bignumber.js';
import { Modal } from 'components';
import { ShareFacebookIcon, ShareTwitterIcon } from 'components/Icon/components';
import { BORDER_RADIUS_SMALL, COLOR_BLACK, COLOR_GREY_300, COLOR_ORANGE, COLOR_WHITE } from 'theme/variables';
import { flexHelper } from 'utils/flexHelper';

import { useShareSocials } from '../../../../hooks';

interface StakeUnstakeModalProps {
  open: boolean;
  onClose: () => void;
  claimed: string;
}

export const ClaimSuccessModal: FC<StakeUnstakeModalProps> = ({ open, onClose, claimed }) => {
  const handleShare = useShareSocials(claimed);

  return (
    <Modal open={open} onClose={onClose} modalMaxWidth={350}>
      <Box sx={{ ...flexHelper(), flexDirection: 'column', pt: '30px' }}>
        <Typography variant="h4" textAlign="center" lineHeight="30px" mb={3}>
          Reward was successfully claimed
        </Typography>
        <Box>
          <img src={HappyKuma} alt="success" />
        </Box>
        <Box
          sx={{
            border: `1px solid ${COLOR_GREY_300}`,
            borderRadius: BORDER_RADIUS_SMALL,
            width: '100%',
            backgroundColor: COLOR_BLACK,
            p: '18px',
            mb: 3,
          }}
        >
          <Typography variant="subtitle1" textAlign="center" sx={{ opacity: 1 }}>
            Your reward:
          </Typography>
          <Typography variant="h4" color={COLOR_ORANGE} fontWeight={600} textTransform="uppercase" textAlign="center">
            {new BigNumber(claimed).toFixed(2)} USDC
          </Typography>
        </Box>
        <Typography variant="subtitle2" color={COLOR_WHITE} mb={2}>
          Share now with
        </Typography>
        <Grid container gap={3} mb="6px">
          <Grid item>
            <FacebookShareButton url="https://www.kumatokens.com/" quote="Hello!" hashtag="#KumaSwapX">
              <ShareFacebookIcon />
            </FacebookShareButton>
          </Grid>
          <Grid item>
            <IconButton onClick={() => handleShare('twitter')}>
              <ShareTwitterIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};
