import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const CloseIcon: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 25 24"
    sx={{
      ...sx,
      width: '25px',
      height: '24px',
    }}
  >
    <g opacity="0.4">
      <path
        d="M6.19629 18L18.1963 6M6.19629 6L18.1963 18"
        stroke="#E6E5E5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </SvgIcon>
);
