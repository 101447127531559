import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const ShareFacebookIcon: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 24 25"
    sx={{
      width: '24px',
      height: '25px',
      ...sx,
    }}
  >
    <g clipPath="url(#clip0_4_7107)">
      <path
        d="M24 12.2001C24 5.57265 18.6274 0.200073 12 0.200073C5.37258 0.200073 0 5.57265 0 12.2001C0 18.1895 4.3882 23.1541 10.125 24.0543V15.6688H7.07812V12.2001H10.125V9.55632C10.125 6.54882 11.9166 4.88757 14.6576 4.88757C15.9701 4.88757 17.3438 5.12195 17.3438 5.12195V8.07507H15.8306C14.34 8.07507 13.875 9.00015 13.875 9.95007V12.2001H17.2031L16.6711 15.6688H13.875V24.0543C19.6118 23.1541 24 18.1895 24 12.2001Z"
        fill="#FF8400"
      />
    </g>
    <defs>
      <clipPath id="clip0_4_7107">
        <rect width="24" height="24" fill="white" transform="translate(0 0.200073)" />
      </clipPath>
    </defs>
  </SvgIcon>
);
