import { FC } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import BigNumber from 'bignumber.js';
import { ShareIcon, UsdcIcon } from 'components/Icon/components';
import { BORDER_RADIUS_SMALL, COLOR_GREY_300, COLOR_ORANGE } from 'theme/variables';

interface TotalRewardsProps {
  userTotalReward: string | number;
  onOpenShareModal?: () => void;
}

export const TotalRewards: FC<TotalRewardsProps> = ({ userTotalReward, onOpenShareModal }) => {
  const handleOpenShareModal = () => {
    if (+userTotalReward > 0) {
      onOpenShareModal();
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        border: `1px solid ${COLOR_GREY_300}`,
        borderRadius: BORDER_RADIUS_SMALL,
        p: 2,
        position: 'relative',
        cursor: onOpenShareModal && 'pointer',
        '&:hover': onOpenShareModal && {
          borderColor: COLOR_ORANGE,
          svg: {
            path: {
              fill: COLOR_ORANGE,
            },
          },
        },
      }}
      onClick={onOpenShareModal ? handleOpenShareModal : undefined}
    >
      {onOpenShareModal && (
        <Box sx={{ position: 'absolute', right: '16px', top: '16px' }}>
          <ShareIcon />
        </Box>
      )}
      <Typography variant="body2" sx={{ mb: 3 }}>
        Total rewards earned
      </Typography>
      <Grid container gap={3} alignItems="center" flexWrap="nowrap" sx={{ overflow: 'hidden' }}>
        <Grid item>
          <UsdcIcon />
        </Grid>
        <Grid item>
          <Typography variant="h2" noWrap whiteSpace="nowrap">
            {new BigNumber(userTotalReward).toFixed(2)}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
