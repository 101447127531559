import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const BulbIcon: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 33 33"
    sx={{
      ...sx,
      width: '33px',
      height: '33px',
    }}
  >
    <path
      d="M13.3848 23.1667H19.6153M16.5001 4.5V5.83333M24.9853 8.01472L24.0425 8.95753M28.5001 16.4999H27.1668M5.83346 16.4999H4.50012M8.95758 8.95753L8.01477 8.01472M11.786 21.2141C9.18251 18.6106 9.18251 14.3895 11.786 11.786C14.3895 9.18249 18.6106 9.18249 21.2141 11.786C23.8176 14.3895 23.8176 18.6106 21.2141 21.2141L20.4846 21.9435C19.6408 22.7874 19.1667 23.9319 19.1667 25.1253V25.8333C19.1667 27.3061 17.9728 28.5 16.5001 28.5C15.0273 28.5 13.8334 27.3061 13.8334 25.8333V25.1253C13.8334 23.9319 13.3593 22.7874 12.5155 21.9435L11.786 21.2141Z"
      stroke="#FF8400"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
