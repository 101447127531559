import BigNumber from 'bignumber.js';

export const useShareSocials = (userTotalReward: string | number) => {
  return (socialType) => {
    const url = 'https://www.kumatokens.com/';
    const text =
      'AMAZING! 🎉\n' +
      '\n' +
      `I just earned FREE ${new BigNumber(userTotalReward).toFixed(2)} $USDC for staking $dKUMA on #dKumaBreeder!\n` +
      '\n' +
      'THE INFINITE MONEY LOOP! ♾\n' +
      '\n' +
      'Stake #memecoins earn $dKUMA,\n' +
      '\n' +
      'stake $dKUMA earn $USDC.\n' +
      '\n' +
      'Powered by #KumaSwapX\n' +
      '\n';
    if (socialType === 'twitter') {
      window.open(
        `http://x.com/share?url=${encodeURIComponent(url)}&text=${encodeURIComponent(text)}`,
        '',
        'left=0,top=0,width=550,height=450,personalbar=0,toolbar=0,scrollbars=0,resizable=0',
      );
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      FB.ui({
        display: 'popup',
        method: 'share',
        href: 'https://google.com/',
      });
    }
  };
};
