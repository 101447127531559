import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const TelegramIcon: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 40 40"
    sx={{
      ...sx,
      width: '40px',
      height: '40px',
    }}
  >
    <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" fill="#141823" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30 20C30 25.5228 25.5228 30 20 30C14.4772 30 10 25.5228 10 20C10 14.4772 14.4772 10 20 10C25.5228 10 30 14.4772 30 20ZM20.3584 17.3824C19.3857 17.787 17.4418 18.6243 14.5266 19.8944C14.0532 20.0827 13.8052 20.2668 13.7827 20.4469C13.7445 20.7512 14.1256 20.8711 14.6446 21.0343C14.7152 21.0565 14.7883 21.0795 14.8633 21.1038C15.3739 21.2698 16.0607 21.464 16.4178 21.4717C16.7416 21.4787 17.1031 21.3452 17.5022 21.0711C20.226 19.2325 21.632 18.3032 21.7203 18.2831C21.7825 18.269 21.8688 18.2512 21.9273 18.3032C21.9858 18.3552 21.98 18.4536 21.9738 18.48C21.9361 18.6409 20.4401 20.0317 19.6659 20.7515C19.4246 20.9759 19.2534 21.135 19.2184 21.1714C19.14 21.2528 19.0601 21.3298 18.9833 21.4038C18.509 21.8611 18.1533 22.204 19.003 22.764C19.4114 23.0331 19.7381 23.2556 20.0641 23.4776C20.4202 23.7201 20.7752 23.9619 21.2347 24.263C21.3517 24.3398 21.4635 24.4195 21.5724 24.4971C21.9867 24.7925 22.359 25.0578 22.8188 25.0155C23.086 24.9909 23.3621 24.7397 23.5022 23.9903C23.8335 22.2193 24.4847 18.382 24.6352 16.8008C24.6484 16.6623 24.6318 16.485 24.6185 16.4071C24.6052 16.3293 24.5773 16.2184 24.4762 16.1363C24.3563 16.0391 24.1714 16.0186 24.0886 16.0201C23.7125 16.0267 23.1355 16.2273 20.3584 17.3824Z"
      fill="#3D414D"
    />
    <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="#3D414D" />
  </SvgIcon>
);
