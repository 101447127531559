import BigNumber from 'bignumber.js';
import { takeLatest } from 'redux-saga/effects';
import { erc20Abi } from 'services/WalletService/config/abi';
import { notifyText } from 'services/WalletService/config/constants';
import { error, request, success } from 'store/api/actions';
import { call, put, select } from 'typed-redux-saga';
import { Erc20Abi, Modals } from 'types';
import { getToastMessage } from 'utils';
import { AbiItem } from 'web3-utils';

import { setActiveModal } from '../../modals/reducer';
import { approve } from '../actions';
import actionTypes from '../actionTypes';
import userSelector from '../selectors';

export function* approveSaga({
  type,
  payload: { web3Provider, spenderAddress, amount, tokenAddress },
}: ReturnType<typeof approve>) {
  yield* put(request(type));
  const userAddress: string = yield select(userSelector.getProp('address'));

  try {
    const tokenContract: Erc20Abi = yield new web3Provider.eth.Contract(erc20Abi as AbiItem[], tokenAddress);
    const allowance: string = yield call(tokenContract.methods.allowance(userAddress, spenderAddress).call);

    if (new BigNumber(allowance).isLessThan(amount)) {
      yield put(
        setActiveModal({
          activeModal: Modals.ApprovePending,
          open: true,
        }),
      );

      yield call(tokenContract.methods.approve(spenderAddress, amount).send, {
        from: userAddress,
      });

      yield put(
        setActiveModal({
          activeModal: Modals.none,
          open: false,
        }),
      );

      getToastMessage('success', notifyText.approve.success);
    }

    yield* put(success(type));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);

    yield put(
      setActiveModal({
        activeModal: Modals.ApproveRejected,
        open: true,
      }),
    );

    getToastMessage('error', notifyText.approve.error);
    yield* put(error(type));
    throw err;
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.APPROVE, approveSaga);
}
