import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Grid } from '@mui/material';
import { MainLogo } from 'components/Icon/components';
import { useShallowSelector } from 'hooks';
import { useWalletConnectorContext } from 'services';
import { updateUserState } from 'store/user/reducer';
import userSelector from 'store/user/selectors';
import { State, UserState } from 'types';

import { ConnectPopover, TipPopover } from './components';

export const Header: FC = () => {
  const dispatch = useDispatch();
  const { address, tokenBalance, isConnectTipAccepted } = useShallowSelector<State, UserState>(userSelector.getUser);
  const { connect, disconnect } = useWalletConnectorContext();
  const hideTip = () => {
    dispatch(updateUserState({ isConnectTipAccepted: true }));
  };

  return (
    <Box
      sx={{
        pt: { xs: '44px', sm: '34px' },
        pb: { xs: 2, sm: '22px' },
      }}
    >
      <Grid container justifyContent={{ xs: 'flex-start', sm: 'flex-start' }} alignItems="center">
        <Grid item>
          <MainLogo />
        </Grid>
        <TipPopover showPopover={!isConnectTipAccepted} hideTip={hideTip} />
        <ConnectPopover
          userAddress={address}
          userBalance={tokenBalance}
          onConnectWallet={connect}
          onDisconnect={disconnect}
        />
      </Grid>
    </Box>
  );
};
