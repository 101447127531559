import { FC, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { CopyText } from 'components';
import { Modal } from 'components/Modal';
import { useShallowSelector } from 'hooks';
import { setActiveModal } from 'store/modals/reducer';
import modalsSelector from 'store/modals/selectors';
import userSelector from 'store/user/selectors';
import { FontFamilies } from 'theme/Typography';
import { COLOR_ORANGE } from 'theme/variables';
import { Modals, ModalsInitialState, State, UserState } from 'types/store';
import { generateLinkToScan } from 'utils';
import { flexHelper } from 'utils/flexHelper';

import { modalData } from './NotificationModal.helpers';

export const NotificationModal: FC = () => {
  const dispatch = useDispatch();

  const {
    modalState: { activeModal, txHash },
  } = useShallowSelector<State, ModalsInitialState>(modalsSelector.getModals);
  const { chainType } = useShallowSelector<State, UserState>(userSelector.getUser);

  const isModalOpen = useMemo(
    () =>
      [
        Modals.ApprovePending,
        Modals.ApproveRejected,
        Modals.SendSuccess,
        Modals.StakePending,
        Modals.SendPending,
        Modals.SendRejected,
        Modals.SendError,
      ].includes(activeModal),
    [activeModal],
  );

  const closeModal = useCallback(() => {
    dispatch(
      setActiveModal({
        activeModal: Modals.none,
        txHash: '',
        open: false,
      }),
    );
  }, [dispatch]);
  const data = modalData[activeModal];

  return (
    <Modal open={isModalOpen} onClose={closeModal} modalMaxWidth={627}>
      <Box sx={{ p: '8px 21px 40px', ...flexHelper(), flexDirection: 'column' }}>
        <Typography
          variant="h5"
          align="center"
          sx={{ span: { color: COLOR_ORANGE, fontFamily: FontFamilies.primary }, mb: '37px' }}
        >
          {data?.title1} <span>{data?.title2}</span>
        </Typography>
        <Box display="flex" justifyContent="center" sx={{ mb: 3 }}>
          {data?.icon && <data.icon />}
        </Box>
        <Typography align="center" variant="h4" maxWidth={340} mb="17px" lineHeight="30px">
          {data?.title3}
        </Typography>

        {activeModal === Modals.ApprovePending && (
          <Typography align="center" variant="subtitle1" sx={{ opacity: '1 !important' }}>
            {data?.title4}
          </Typography>
        )}
        {activeModal === Modals.StakePending && (
          <Typography align="center" variant="subtitle1" maxWidth={293} sx={{ opacity: '1 !important' }}>
            {data?.title4}
          </Typography>
        )}
        {activeModal === Modals.SendPending && (
          <Typography align="center" variant="subtitle1" maxWidth={293} sx={{ opacity: '1 !important' }}>
            {data?.title4}
          </Typography>
        )}
        {activeModal === Modals.SendSuccess && (
          <Typography align="center" variant="subtitle1" maxWidth={212} sx={{ opacity: '1 !important' }}>
            {data?.title4}
          </Typography>
        )}
        {(activeModal === Modals.SendRejected || activeModal === Modals.SendError) && (
          <Typography align="center" variant="body1" maxWidth={374} sx={{ opacity: '1 !important' }}>
            {data?.title4}
          </Typography>
        )}

        {activeModal === Modals.SendSuccess && (
          <Box sx={{ mt: '12px', width: '100%' }}>
            <CopyText text={generateLinkToScan(txHash, chainType === 'mainnet')} />
          </Box>
        )}
      </Box>
    </Modal>
  );
};
