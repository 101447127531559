import BigNumber from 'bignumber.js';
import { select, takeEvery } from 'redux-saga/effects';
import { ContractsNames } from 'services/WalletService/config';
import { error, request, success } from 'store/api/actions';
import userSelector from 'store/user/selectors';
import { call, put } from 'typed-redux-saga';
import { StakingAbi, UserState } from 'types';
import { fromDecimalsDisplay, getContractDataByItsName } from 'utils';

import { getUserStakingInfo } from '../actions';
import actionTypes from '../actionTypes';
import { updateUserState } from '../reducer';

export function* getUserStakingInfoSaga({ type, payload: { web3Provider } }: ReturnType<typeof getUserStakingInfo>) {
  yield* put(request(type));
  const { address: userAddress, chainType }: UserState = yield select(userSelector.getUser);

  const [stakingAbi, stakingAddress] = getContractDataByItsName(ContractsNames.staking, chainType);
  try {
    const stakingContract: StakingAbi = yield new web3Provider.eth.Contract(stakingAbi, stakingAddress);

    const userInfo = yield* call(stakingContract.methods.stakeInfo(userAddress).call);
    let pendingReward = yield* call(stakingContract.methods.pendingReward(userAddress).call);
    if (typeof userInfo.amount === 'bigint') userInfo.amount = userInfo.amount.toString();
    if (typeof userInfo.enteredAt === 'bigint') userInfo.enteredAt = userInfo.enteredAt.toString();
    if (typeof userInfo.rewardTakenActual === 'bigint')
      userInfo.rewardTakenActual = userInfo.rewardTakenActual.toString();
    if (typeof pendingReward === 'bigint') pendingReward = pendingReward.toString();
    if (typeof userInfo.bag === 'bigint') userInfo.bag = userInfo.bag.toString();

    yield put(
      updateUserState({
        stakingInfo: {
          staked: fromDecimalsDisplay(userInfo.amount),
          enteredAt: userInfo.enteredAt,
          totalReward: fromDecimalsDisplay(userInfo.rewardTakenActual, 6),
          // ! removed userInfo.bag
          pendingReward: fromDecimalsDisplay(new BigNumber(pendingReward).toString(), 6),
        },
      }),
    );

    yield* put(success(type));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    yield* put(error(type));
  }
}

export default function* listener() {
  yield takeEvery(actionTypes.UPDATE_USER_DATA, getUserStakingInfoSaga);
}
