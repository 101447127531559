import { Box, styled } from '@mui/material';
import { LogoLoaderIcon } from 'components/Icon/components';
import { COLOR_BLUE_DARK, COLOR_ORANGE } from 'theme/variables';
import { flexHelper } from 'utils/flexHelper';

const ProgressBar = styled(Box)(({ theme }) => ({
  height: 3,
  backgroundColor: COLOR_ORANGE,
  animation: 'load 3s infinite',
  '@keyframes load': {
    '0%': {
      width: 0,
    },
    '100%': {
      width: '100%',
    },
  },
  [theme.breakpoints.up('sm')]: {
    height: 2.5,
  },
}));

export const LoadingScreen = () => {
  return (
    <Box
      sx={{
        background: COLOR_BLUE_DARK,
        width: '100%',
        height: '100vh',
        position: 'fixed',
        zIndex: 3000,
        ...flexHelper('center', 'center'),
      }}
    >
      <Box sx={{ position: 'relative' }}>
        <LogoLoaderIcon sx={{ width: { xs: 226, sm: 452 } }} />
        <Box sx={{ width: { xs: 182, sm: 367 }, position: 'absolute', right: 1, bottom: { xs: 53, sm: 45 } }}>
          <ProgressBar />
        </Box>
      </Box>
    </Box>
  );
};
