export const notifyText = {
  disconnect: {
    info: 'Disconnect from wallet',
  },
  approve: {
    success: 'Successfully approved',
    error: 'Approve error',
  },
  stake: {
    success: 'You staked successfully',
    error: 'Stake error',
  },
  unstake: {
    success: 'You unstaked successfully',
    error: 'Unstake error',
  },
  claim: {
    success: 'You claimed successfully',
    error: 'Claim error',
  },
};
