import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material';

import { FontWeights } from '../Typography';
import { BORDER_RADIUS_SMALL, COLOR_BLACK_DARK, COLOR_GREY_300, COLOR_ORANGE, COLOR_WHITE } from '../variables';

export const getMuiInputBase = (theme: Theme): ComponentsOverrides['MuiInputBase'] => ({
  root: {
    background: COLOR_BLACK_DARK,
  },
  input: {
    borderRadius: theme.spacing(1.5),
    padding: '24px 18px 12px 18px !important',
  },
});

export const getMuiTextFieldOverrides = (): ComponentsOverrides['MuiTextField'] => ({
  root: {
    maxWidth: 302,
    boxShadow: 'none',
    border: `1px solid ${COLOR_GREY_300}`,
    borderRadius: BORDER_RADIUS_SMALL,
    fontSize: '14px',
    backgroundColor: COLOR_BLACK_DARK,
    '& input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
      appearance: 'none',
      margin: 0,
    },
    '& input[type=number]': {
      appearance: 'textfield',
    },
    '& input::placeholder': {
      opacity: 1,
      color: '#AAAAAA',
      fontWeight: FontWeights.fontWeightRegular,
    },
    '& .MuiOutlinedInput-root': {
      color: COLOR_WHITE,

      '& fieldset': {
        border: '1px solid',
        borderColor: COLOR_GREY_300,
      },
      '&:hover fieldset': {
        borderColor: COLOR_ORANGE,
      },
      '&.Mui-focused fieldset': {
        borderColor: COLOR_ORANGE,
      },
    },
  },
});

export const getMuiTextFieldDefaultProps = (): ComponentsProps['MuiTextField'] => ({
  inputProps: {
    style: {
      height: '44px',
      padding: '0 12px',
    },
  },
});
