import BigNumber from 'bignumber.js';
import { call, select } from 'redux-saga/effects';
import { ContractsNames } from 'services/WalletService/config';
import { error, request } from 'store/api/actions';
import { put, takeLatest } from 'typed-redux-saga';
import { UniswapRouterAbi, UserState } from 'types';
import { createContract, fromDecimalsDisplay, getContractDataByItsName } from 'utils';
import Web3 from 'web3';

import userSelector from '../../user/selectors';
import { calculateTvl } from '../actions';
import actionTypes from '../actionTypes';
import { updateStakingState } from '../reducer';

export function* calculateTvlSaga({ type, payload: { totalStaked } }: ReturnType<typeof calculateTvl>) {
  yield put(request(type));

  const { chainType, network }: UserState = yield select(userSelector.getUser);
  const [routerAbi, routerAddress] = getContractDataByItsName(ContractsNames.uniswapRouter, chainType);
  const [, tokenAddress] = getContractDataByItsName(ContractsNames.token, chainType);
  const [, usdcTokenAddress] = getContractDataByItsName(ContractsNames.usdc, chainType);
  const [, wethTokenAddress] = getContractDataByItsName(ContractsNames.weth, chainType);
  try {
    const routerContract: UniswapRouterAbi = yield createContract(routerAddress, routerAbi, network, chainType);
    const web3 = new Web3();

    const tokenToSell = web3.utils.toWei('1', 'ether');
    const tokenAmountOut = yield call(
      routerContract.methods.getAmountsOut(tokenToSell, [tokenAddress, wethTokenAddress]).call,
    );
    const usdcAmountOut = yield call(
      routerContract.methods.getAmountsOut(tokenAmountOut[1], [wethTokenAddress, usdcTokenAddress]).call,
    );

    yield put(
      updateStakingState({
        tvl: new BigNumber(fromDecimalsDisplay(usdcAmountOut[1], 6))
          .multipliedBy(fromDecimalsDisplay(totalStaked))
          .toString(),
      }),
    );
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    yield put(error(type));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.CALCULATE_TVL, calculateTvlSaga);
}
