import { FC } from 'react';
import { FacebookShareButton } from 'react-share';
import { Box, Button, Typography } from '@mui/material';
import { HappyKuma } from 'assets/img';
import { Modal } from 'components';
import { ShareFacebookIcon, ShareTwitterIcon } from 'components/Icon/components';
import { flexHelper } from 'utils/flexHelper';

import { useShareSocials } from '../../../../hooks';
import { TotalRewards } from '../TotalRewards';

interface StakeUnstakeModalProps {
  open: boolean;
  onClose: () => void;
  userTotalReward: string | number;
}

export const ShareModal: FC<StakeUnstakeModalProps> = ({ open, onClose, userTotalReward }) => {
  const handleShare = useShareSocials(userTotalReward);

  return (
    <Modal open={open} onClose={onClose} modalMaxWidth={531}>
      <Box sx={{ ...flexHelper(), flexDirection: 'column', pt: '30px', px: { xs: 0, md: '27px' }, pb: '50px' }}>
        <Typography fontSize="24px" lineHeight="32px" mb={{ xs: 0, md: '57px' }}>
          Share your rewards
        </Typography>
        <Box>
          <img src={HappyKuma} alt="success" />
        </Box>
        <TotalRewards userTotalReward={userTotalReward} />
        <Button
          variant="outlined"
          fullWidth
          sx={{ mt: 3, fontWeight: 400, textTransform: 'none' }}
          startIcon={<ShareTwitterIcon />}
          onClick={() => handleShare('twitter')}
        >
          Share twitter
        </Button>
        <FacebookShareButton
          url="https://www.kumatokens.com/"
          quote="Hello!"
          hashtag="#KumaSwapX"
          className="share-facebook_btn"
        >
          <ShareFacebookIcon />
          Share facebook
        </FacebookShareButton>
        {/* <Button */}
        {/*  variant="outlined" */}
        {/*  fullWidth */}
        {/*  sx={{ mt: 1, fontWeight: 400, textTransform: 'none' }} */}
        {/*  startIcon={<ShareFacebookIcon />} */}
        {/*  onClick={() => handleShare('facebook')} */}
        {/* > */}
        {/*  Share facebook */}
        {/* </Button> */}
      </Box>
    </Modal>
  );
};
