import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Box, Container } from '@mui/material';
import { useShallowSelector, useSmoothTopScroll } from 'hooks';
import { useWalletConnectorContext } from 'services';
import { getStakingData } from 'store/staking/actions';
import { updateUserData } from 'store/user/actions';
import userSelector from 'store/user/selectors';

import { Footer, Header, LoadingScreen, NotificationModal } from '..';

export interface LayoutProps {
  children?: ReactNode;
}

export const Layout: FC<LayoutProps> = ({ children }) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const { walletService } = useWalletConnectorContext();
  const address = useShallowSelector(userSelector.getProp('address'));

  const [isLoading, setIsLoading] = useState(true);

  const firstPathAtPathname = useMemo(() => pathname.split('/')[1], [pathname]);
  useSmoothTopScroll(firstPathAtPathname);

  useEffect(() => {
    if (address.length) {
      dispatch(
        updateUserData({
          web3Provider: walletService.Web3(),
        }),
      );
    }
  }, [address.length, dispatch, walletService]);
  useEffect(() => {
    dispatch(getStakingData());
  }, [dispatch]);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, []);

  return (
    <Box sx={{ position: 'relative' }}>
      {isLoading && <LoadingScreen />}
      <Container sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
        <Header />
        {children}
        <Footer />
      </Container>
      <NotificationModal />
    </Box>
  );
};
