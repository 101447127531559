import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const FacebookIcon: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 40 40"
    sx={{
      ...sx,
      width: '40px',
      height: '40px',
    }}
  >
    <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" fill="#141823" />
    <rect x="9.5" y="9.5" width="21" height="21" rx="10.5" fill="#0A0B11" fillOpacity="0.76" />
    <path
      d="M31 20C31 13.9249 26.0751 9 20 9C13.9249 9 9 13.9249 9 20C9 25.4903 13.0225 30.0412 18.2813 30.8664V23.1797H15.4883V20H18.2813V17.5766C18.2813 14.8197 19.9235 13.2969 22.4361 13.2969C23.6392 13.2969 24.8984 13.5117 24.8984 13.5117V16.2187H23.5114C22.145 16.2187 21.7188 17.0667 21.7188 17.9375V20H24.7695L24.2818 23.1797H21.7188V30.8664C26.9775 30.0412 31 25.4903 31 20Z"
      fill="#3D414D"
    />
    <rect x="9.5" y="9.5" width="21" height="21" rx="10.5" stroke="#3D414D" />
    <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="#3D414D" />
  </SvgIcon>
);
