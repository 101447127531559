import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const ErrorIcon: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 100 100"
    sx={{
      ...sx,
      width: '100px',
      height: '100px',
    }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M50 100C77.6142 100 100 77.6142 100 50C100 22.3858 77.6142 0 50 0C22.3858 0 0 22.3858 0 50C0 77.6142 22.3858 100 50 100ZM42.7425 22.1592H57.2556L55.9283 63.1593H44.0698L42.7425 22.1592ZM57.5153 74.5851C57.4576 78.7399 53.9663 82.0868 50.0135 82.0868C45.8587 82.0868 42.454 78.7399 42.5117 74.5851C42.454 70.4879 45.8587 67.1699 50.0135 67.1699C53.9663 67.1699 57.4576 70.4879 57.5153 74.5851Z"
      fill="#FF8400"
    />
  </SvgIcon>
);
