import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material';

import { BORDER_RADIUS_LARGE, COLOR_GREY_300, COLOR_WHITE } from '../variables';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getMuiPopoverOverrides = (theme?: Theme): ComponentsOverrides['MuiPopover'] => ({
  root: {
    '.MuiBackdrop-root': {
      background: 'rgba(11, 12, 17, 0.74)',
      backdropFilter: 'blur(18px)',
    },
  },
  paper: {
    background: 'rgba(10, 11, 17, 0.76)',
    border: `1px solid ${COLOR_GREY_300}`,
    borderRadius: BORDER_RADIUS_LARGE,
    boxShadow: 'none',
    width: 'auto',
    color: COLOR_WHITE,
  },
});

export const getMuiPopoverDefaultProps = (): ComponentsProps['MuiPopover'] => ({});
