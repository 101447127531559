export * from './connect';
export * from './store';
export * from './contracts';
export * from './routes';

export type TNullable<T> = T | null;
export type TOptionable<T> = T | undefined;

export type DateLike = string | number | Date;

export enum WalletProviders {
  metamask = 'MetaMask',
  walletConnect = 'WalletConnect',
}

export interface INotifyModalProps {
  currData?: {
    [k: string]: string;
  };
  closeModal: () => void;
}

export enum Modules {
  core = 'core',
  landing = 'landing',
}

export type Nullable<T> = null | T;
