const GET_STAKING_DATA = 'GET_STAKING_DATA' as const;
const STAKE = 'STAKE' as const;
const UNSTAKE = 'UNSTAKE' as const;
const CLAIM = 'CLAIM' as const;
const CALCULATE_TVL = 'CALCULATE_TVL' as const;

export default {
  GET_STAKING_DATA,
  STAKE,
  UNSTAKE,
  CLAIM,
  CALCULATE_TVL,
};
