import { ContractsNames } from 'services/WalletService/config';
import { notifyText } from 'services/WalletService/config/constants';
import apiActions from 'store/api/actions';
import actionTypes from 'store/staking/actionTypes';
import userActionTypes from 'store/user/actionTypes';
import { approveSaga } from 'store/user/sagas/approve';
import { updateUserDataSaga } from 'store/user/sagas/updateUserData';
import userSelector from 'store/user/selectors';
import { call, put, select, takeLatest } from 'typed-redux-saga';
import { Modals, StakingAbi } from 'types';
import { getContractDataByItsName, getToastMessage, toDecimals } from 'utils';

import { setActiveModal } from '../../modals/reducer';
import { stake } from '../actions';

import { getStakingDataSaga } from './getStakingData';

function* stakeSaga({ type, payload: { web3Provider, amount } }: ReturnType<typeof stake>) {
  yield put(apiActions.request(type));

  const { address: userAddress, chainType } = yield select(userSelector.getUser);
  const [stakingAbi, stakingAddress] = getContractDataByItsName(ContractsNames.staking, chainType);
  const [, tokenAddress] = getContractDataByItsName(ContractsNames.token, chainType);

  const amountWithDecimals = toDecimals(amount);
  try {
    yield call(approveSaga, {
      type: userActionTypes.APPROVE,
      payload: {
        web3Provider,
        spenderAddress: stakingAddress,
        tokenAddress,
        amount: amountWithDecimals,
      },
    });

    yield put(
      setActiveModal({
        activeModal: Modals.StakePending,
        open: true,
      }),
    );

    const stakingContract: StakingAbi = yield new web3Provider.eth.Contract(stakingAbi, stakingAddress);
    const { transactionHash } = yield call(stakingContract.methods.deposit(amountWithDecimals).send, {
      from: userAddress,
    });

    yield call(updateUserDataSaga, { type: userActionTypes.UPDATE_USER_DATA, payload: { web3Provider } });
    yield call(getStakingDataSaga, { type: actionTypes.GET_STAKING_DATA, payload: { web3Provider } });

    yield put(
      setActiveModal({
        activeModal: Modals.SendSuccess,
        open: false,
        txHash: transactionHash,
      }),
    );

    getToastMessage('success', notifyText.stake.success);

    yield put(apiActions.success(type));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);

    yield put(
      setActiveModal({
        activeModal: Modals.SendRejected,
        open: true,
      }),
    );

    getToastMessage('error', notifyText.stake.error);

    yield put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.STAKE, stakeSaga);
}
