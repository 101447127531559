import { FC } from 'react';
import { Tooltip, Typography } from '@mui/material';
import BigNumber from 'bignumber.js';
import { useShallowSelector } from 'hooks';
import stakingSelector from 'store/staking/selectors';
import { BORDER_RADIUS_DEFAULT, COLOR_GREY_300, COLOR_ORANGE, COLOR_WHITE } from 'theme/variables';
import { StakingState, State } from 'types';

export const StakingStats: FC = () => {
  const { totalStaked, tvl } = useShallowSelector<State, StakingState>(stakingSelector.getStaking);
  return (
    <>
      <Typography variant="body2" mb={1}>
        Total staked
      </Typography>
      <Typography variant="h4" sx={{ span: { color: COLOR_ORANGE }, mb: { xs: 2, xl: '30px' } }}>
        {totalStaked} <span>dKUMA</span>
      </Typography>
      <Tooltip title={new BigNumber(tvl).toFixed(2)}>
        <Typography
          variant="body2"
          textTransform="uppercase"
          color={COLOR_WHITE}
          sx={{
            span: { color: COLOR_ORANGE },
            border: `1px solid ${COLOR_GREY_300}`,
            borderRadius: BORDER_RADIUS_DEFAULT,
            p: '10px',
            fontWeight: 600,
            fontSize: '16px !important',
          }}
          noWrap
          maxWidth={{ xs: '100%', xl: 345 }}
        >
          <span>TVL:</span> ${new BigNumber(tvl).toFixed(2)}
        </Typography>
      </Tooltip>
    </>
  );
};
