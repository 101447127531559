import { FC, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import BigNumber from 'bignumber.js';
import { RefreshIcon, TokenIcon, UsdcIcon } from 'components/Icon/components';
import { useModal, useShallowSelector } from 'hooks';
import { useWalletConnectorContext } from 'services';
import { claim, getStakingData } from 'store/staking/actions';
import actionTypes from 'store/staking/actionTypes';
import stakingSelector from 'store/staking/selectors';
import uiSelector from 'store/ui/selectors';
import { updateUserData } from 'store/user/actions';
import userSelector from 'store/user/selectors';
import { BORDER_RADIUS_LARGE, COLOR_GREY_300 } from 'theme/variables';
import { RequestStatus, StakingState, State, UserState } from 'types';
import { getCurrentTimestampInSeconds } from 'utils';
import { flexHelper } from 'utils/flexHelper';

import { StakingStats } from '../StakingStats';

import { ClaimSuccessModal, Countdown, ShareModal, StakeUnstakeModal, TotalRewards } from './components';

export const StakingInfo: FC = () => {
  const dispatch = useDispatch();
  const { walletService } = useWalletConnectorContext();

  const {
    [actionTypes.CLAIM]: claimRequestStatus,
    [actionTypes.STAKE]: stakeRequestStatus,
    [actionTypes.UNSTAKE]: unstakeRequestStatus,
  } = useShallowSelector(uiSelector.getUI);

  const [isStakeUnstakeModalVisible, onOpenStakeUnstakeModal, onCloseStakeUnstakeModal] = useModal(false);
  const [isClaimSuccessModalVisible, onOpenClaimSuccessModal, onCloseClaimSuccessModal] = useModal(false);
  const [isShareModalVisible, onOpenShareModal, onCloseShareModal] = useModal(false);

  const { harvestPeriod, totalStaked } = useShallowSelector<State, StakingState>(stakingSelector.getStaking);
  const { stakingInfo, address, currentlyClaimed } = useShallowSelector<State, UserState>(userSelector.getUser);

  const userPercentStaked = useMemo(
    () =>
      +totalStaked > 0 ? new BigNumber(stakingInfo.staked).multipliedBy(100).dividedBy(totalStaked).toFixed(0) : '0',
    [stakingInfo.staked, totalStaked],
  );

  const canUserClaim = useMemo(
    () =>
      +stakingInfo.enteredAt === 0 ? false : getCurrentTimestampInSeconds() > +stakingInfo.enteredAt + +harvestPeriod,
    [harvestPeriod, stakingInfo],
  );

  const isClaimBtnDisabled = useMemo(
    () => !canUserClaim || +stakingInfo.pendingReward === 0 || address.length === 0,
    [address.length, canUserClaim, stakingInfo],
  );

  const handleClaim = () => {
    dispatch(
      claim({
        web3Provider: walletService.Web3(),
      }),
    );
  };

  const handleUpdateState = () => {
    dispatch(getStakingData);
    if (address.length) {
      dispatch(
        updateUserData({
          web3Provider: walletService.Web3(),
        }),
      );
    }
  };

  useEffect(() => {
    if (claimRequestStatus === RequestStatus.SUCCESS) {
      dispatch({ type: `${actionTypes.CLAIM}_RESET` });
      onOpenClaimSuccessModal();
    }
  }, [claimRequestStatus, dispatch, onOpenClaimSuccessModal]);

  useEffect(() => {
    if (stakeRequestStatus === RequestStatus.SUCCESS || unstakeRequestStatus === RequestStatus.SUCCESS) {
      dispatch({ type: `${actionTypes.STAKE}_RESET` });
      dispatch({ type: `${actionTypes.UNSTAKE}_RESET` });
      onCloseStakeUnstakeModal();
    }
  }, [dispatch, onCloseStakeUnstakeModal, stakeRequestStatus, unstakeRequestStatus]);

  return (
    <Box sx={{ maxWidth: { xs: 'auto', xl: 865 }, width: '100%' }}>
      <Box sx={{ display: { xs: 'block', xl: 'none' }, textAlign: 'center', mb: '11px' }}>
        <StakingStats />
      </Box>
      <Box
        sx={{
          border: `1px solid ${COLOR_GREY_300}`,
          borderRadius: BORDER_RADIUS_LARGE,
          px: { xs: 2, md: '34px' },
          pt: { xs: '14px', md: '23px' },
          pb: { xs: 3, md: '28px' },
        }}
      >
        <Box sx={{ pb: { xs: 2, md: '34px' }, mb: { xs: 2, md: '27px' }, borderBottom: `1px solid ${COLOR_GREY_300}` }}>
          <Typography variant="body2" sx={{ mb: '10px' }}>
            Next claim in
          </Typography>
          <Countdown
            deadline={+stakingInfo.enteredAt + +harvestPeriod}
            countdownFinish={getCurrentTimestampInSeconds() > +stakingInfo.enteredAt + +harvestPeriod}
            updateState={handleUpdateState}
          />
        </Box>

        <Box sx={{ pb: { xs: 2, md: '34px' }, mb: { xs: 2, md: '27px' }, borderBottom: `1px solid ${COLOR_GREY_300}` }}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Typography variant="body2" sx={{ mb: { xs: 2, md: '28px' } }}>
                You staked
              </Typography>
              <Grid container gap={3} alignItems="center">
                <Grid item>
                  <TokenIcon sx={{ width: { xs: 29, md: 53 } }} />
                </Grid>
                <Grid item>
                  <Typography variant="h2">{new BigNumber(stakingInfo.staked).toFixed(2)}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ ...flexHelper('space-between'), mb: { xs: 2, md: '28px' } }}>
                <Typography variant="body2">Your current rewards</Typography>
                <IconButton onClick={handleUpdateState}>
                  <RefreshIcon sx={{ color: 'transparent' }} />
                </IconButton>
              </Box>
              <Grid container gap={3} alignItems="center">
                <Grid item>
                  <UsdcIcon sx={{ width: { xs: 31, md: 57 } }} />
                </Grid>
                <Grid item>
                  <Typography variant="h2">{new BigNumber(stakingInfo.pendingReward).toFixed(2)}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ mb: '17px' }}>
          <Grid container>
            <Grid item xs={12} md={6} sx={{ minHeight: { xs: 'auto', md: 135 } }}>
              <Typography variant="body2" sx={{ mb: '10px' }}>
                Your % of the pool
              </Typography>
              <Typography variant="h2">{userPercentStaked}%</Typography>
            </Grid>
            {address.length > 0 && (
              <Grid
                item
                xs={12}
                md={6}
                ml={{ xs: 0, md: -2 }}
                mt={{ xs: 3, md: 0 }}
                sx={{
                  flexBasis: { md: 'calc(50% + 16px) !important' },
                  maxWidth: { md: 'calc(50% + 16px) !important' },
                }}
              >
                <TotalRewards
                  onOpenShareModal={onOpenShareModal}
                  userTotalReward={+stakingInfo.totalReward + +stakingInfo.pendingReward}
                />
              </Grid>
            )}
          </Grid>
        </Box>
        <Button fullWidth onClick={handleClaim} color="secondary" disabled={isClaimBtnDisabled}>
          Claim
        </Button>
      </Box>
      <Button
        sx={{ mt: '20px', display: { xs: 'block', xl: 'none' } }}
        fullWidth
        onClick={onOpenStakeUnstakeModal}
        color="secondary"
      >
        Stake / Unstake
      </Button>
      <StakeUnstakeModal open={isStakeUnstakeModalVisible} onClose={onCloseStakeUnstakeModal} />
      <ClaimSuccessModal
        open={isClaimSuccessModalVisible}
        onClose={onCloseClaimSuccessModal}
        claimed={currentlyClaimed}
      />
      <ShareModal
        open={isShareModalVisible}
        onClose={onCloseShareModal}
        userTotalReward={+stakingInfo.totalReward + +stakingInfo.pendingReward}
      />
    </Box>
  );
};
