import { FC, PropsWithChildren, ReactNode, useMemo } from 'react';
import { Box, Dialog, DialogProps, IconButton, ModalProps as MuiModalProps, Typography } from '@mui/material';
import { CloseIcon } from 'components/Icon/components';
import { flexHelper } from 'utils/flexHelper';

export interface ModalProps extends DialogProps {
  customTitle?: ReactNode;
  open: boolean;
  withCloseIcon?: boolean;
  onClose: () => void;
  sx?: MuiModalProps['sx'];
  modalMaxWidth: number;
}

export const Modal: FC<PropsWithChildren<ModalProps>> = (props) => {
  const { customTitle = '', open, children, onClose, withCloseIcon = true, maxWidth, sx, modalMaxWidth } = props;

  const jsxTitle = useMemo(
    () => (typeof customTitle === 'string' ? <Typography variant="h3">{customTitle}</Typography> : customTitle),
    [customTitle],
  );

  return (
    <Dialog
      transitionDuration={{
        enter: 200,
        exit: 0,
      }}
      maxWidth={maxWidth}
      open={open}
      onClose={onClose}
      sx={{ ...sx, '.MuiDialog-paper': { maxWidth: modalMaxWidth } }}
    >
      {customTitle && (
        <Box
          sx={{
            ...flexHelper('space-between'),
            pb: '28px',
            mb: '28px',
            position: 'relative',
            borderBottom: '1px solid rgba(123,123,123,0.2)',
          }}
        >
          {jsxTitle}
        </Box>
      )}
      {withCloseIcon && (
        <IconButton onClick={onClose} sx={{ position: 'absolute', top: '24px', right: '24px' }}>
          <CloseIcon />
        </IconButton>
      )}
      {children}
    </Dialog>
  );
};
