import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material';
import { COLOR_BLUE_DARK, COLOR_WHITE } from 'theme/variables';

export const getMuiCssBaselineOverrides = (theme: Theme): ComponentsOverrides['MuiCssBaseline'] => ({
  html: {
    WebkitFontSmoothing: 'antialiased', // Antialiasing.
    MozOsxFontSmoothing: 'grayscale', // Antialiasing.
    boxSizing: 'border-box',
  },
  body: {
    overflowX: 'hidden',
    margin: theme.spacing(0),
    background: COLOR_BLUE_DARK,
    color: COLOR_WHITE,
    fontSize: 16,
  },
});

export const getMuiCssBaselineDefaultProps = (): ComponentsProps['MuiCssBaseline'] => ({});
