import { ContractsNames } from 'services/WalletService/config';
import { notifyText } from 'services/WalletService/config/constants';
import apiActions from 'store/api/actions';
import actionTypes from 'store/staking/actionTypes';
import userSelector from 'store/user/selectors';
import { call, put, select, takeLatest } from 'typed-redux-saga';
import { Modals, StakingAbi } from 'types';
import { fromDecimalsDisplay, getContractDataByItsName, getToastMessage } from 'utils';
import Web3 from 'web3';

import { setActiveModal } from '../../modals/reducer';
import userActionTypes from '../../user/actionTypes';
import { updateUserState } from '../../user/reducer';
import { updateUserDataSaga } from '../../user/sagas/updateUserData';
import { claim } from '../actions';

import { getStakingDataSaga } from './getStakingData';

function* claimSaga({ type, payload: { web3Provider } }: ReturnType<typeof claim>) {
  yield put(apiActions.request(type));

  const web3 = new Web3();

  const { address: userAddress, chainType } = yield select(userSelector.getUser);
  const [stakingAbi, stakingAddress] = getContractDataByItsName(ContractsNames.staking, chainType);

  try {
    yield put(
      setActiveModal({
        activeModal: Modals.SendPending,
        open: true,
      }),
    );

    const stakingContract: StakingAbi = yield new web3Provider.eth.Contract(stakingAbi, stakingAddress);
    const data = yield call(stakingContract.methods.harvest().send, {
      from: userAddress,
    });
    const claimed = web3.utils.hexToNumber(data.events[0].raw.data);

    yield put(
      updateUserState({
        currentlyClaimed: fromDecimalsDisplay(claimed, 6),
      }),
    );

    yield call(updateUserDataSaga, { type: userActionTypes.UPDATE_USER_DATA, payload: { web3Provider } });
    yield call(getStakingDataSaga, { type: actionTypes.GET_STAKING_DATA, payload: undefined });

    yield put(
      setActiveModal({
        activeModal: Modals.SendSuccess,
        open: true,
        txHash: data.transactionHash,
      }),
    );

    getToastMessage('success', notifyText.claim.success);

    yield put(apiActions.success(type));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);

    yield put(
      setActiveModal({
        activeModal: Modals.SendRejected,
        open: true,
      }),
    );

    getToastMessage('error', notifyText.claim.error);

    yield put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.CLAIM, claimSaga);
}
