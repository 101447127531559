import { ContractsNames } from 'services/WalletService/config';
import { notifyText } from 'services/WalletService/config/constants';
import apiActions from 'store/api/actions';
import actionTypes from 'store/staking/actionTypes';
import userSelector from 'store/user/selectors';
import { call, put, select, takeLatest } from 'typed-redux-saga';
import { Modals, StakingAbi } from 'types';
import { getContractDataByItsName, getToastMessage, toDecimals } from 'utils';

import { setActiveModal } from '../../modals/reducer';
import userActionTypes from '../../user/actionTypes';
import { updateUserDataSaga } from '../../user/sagas/updateUserData';
import { unstake } from '../actions';

import { getStakingDataSaga } from './getStakingData';

function* unstakeSaga({ type, payload: { web3Provider, amount } }: ReturnType<typeof unstake>) {
  yield put(apiActions.request(type));

  const { address: userAddress, chainType } = yield select(userSelector.getUser);
  const [stakingAbi, stakingAddress] = getContractDataByItsName(ContractsNames.staking, chainType);

  const amountWithDecimals = toDecimals(amount);
  try {
    yield put(
      setActiveModal({
        activeModal: Modals.SendPending,
        open: true,
      }),
    );

    const stakingContract: StakingAbi = yield new web3Provider.eth.Contract(stakingAbi, stakingAddress);
    const { transactionHash } = yield call(stakingContract.methods.withdraw(amountWithDecimals).send, {
      from: userAddress,
    });

    yield call(updateUserDataSaga, { type: userActionTypes.UPDATE_USER_DATA, payload: { web3Provider } });
    yield call(getStakingDataSaga, { type: actionTypes.GET_STAKING_DATA, payload: { web3Provider } });

    yield put(
      setActiveModal({
        activeModal: Modals.SendSuccess,
        open: true,
        txHash: transactionHash,
      }),
    );

    getToastMessage('success', notifyText.unstake.success);

    yield put(apiActions.success(type));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);

    yield put(
      setActiveModal({
        activeModal: Modals.SendRejected,
        open: true,
      }),
    );

    getToastMessage('error', notifyText.unstake.error);

    yield put(apiActions.error(type, err));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.UNSTAKE, unstakeSaga);
}
