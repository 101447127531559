import { FC, MouseEvent, useEffect, useState } from 'react';
import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import { CopyIcon } from 'components/Icon/components';
import { BORDER_RADIUS_SMALL, COLOR_WHITE } from 'theme/variables';

type Size = 'sm' | 'md';
type Variant = 'input' | 'icon';

const sizesState: Record<Size, string> = {
  sm: '170px',
  md: '418px',
};

export interface CopyTextProps {
  size?: Size;
  variant?: Variant;
  text: string;
}

export const CopyText: FC<CopyTextProps> = ({ variant = 'input', size = 'md', text }) => {
  const [helperText, setHelperText] = useState('');

  useEffect(() => {
    const timer = setTimeout(() => {
      setHelperText('');
    }, 1500);
    return () => clearTimeout(timer);
  }, [helperText]);

  const handleCopyAddress = async (e: MouseEvent) => {
    e.stopPropagation();
    try {
      await navigator.clipboard.writeText(text);
      setHelperText('Coppied!');
    } catch (err) {
      setHelperText('Not coppied!');
    }
  };
  return (
    <>
      {variant === 'input' && (
        <Box
          sx={{
            width: '100%',
            borderRadius: BORDER_RADIUS_SMALL,
            border: '1px solid transparent',
            borderColor: size === 'md' ? '#5A5956' : null,
            padding: size === 'md' ? '15px' : '0px',
          }}
        >
          <Grid
            item
            container
            justifyContent="space-between"
            alignItems="center"
            wrap="nowrap"
            xs={12}
            sx={{
              overflow: 'hidden',
            }}
          >
            <Typography
              variant="h6"
              sx={{
                maxWidth: { xs: 1000, md: sizesState[size] },
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontSize: size === 'md' ? '16px !important' : '18px !important',
              }}
            >
              {helperText || text}
            </Typography>
            <IconButton sx={{ pl: 2.5 }} onClick={(e) => handleCopyAddress(e)}>
              <CopyIcon sx={{ path: { stroke: size === 'md' ? COLOR_WHITE : '' } }} />
            </IconButton>
          </Grid>
        </Box>
      )}
      {variant === 'icon' && (
        <Button
          variant="text"
          startIcon={<CopyIcon />}
          sx={{
            p: 0,
          }}
          onClick={(e) => handleCopyAddress(e)}
        />
      )}
    </>
  );
};
