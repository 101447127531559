import { Theme } from '@mui/material';
import { TypographyOptions } from '@mui/material/styles/createTypography';
import { COLOR_GREEN, COLOR_GREY_400, COLOR_ORANGE } from 'theme/variables';

export enum FontWeights {
  fontWeightRegular = 400,
  fontWeightMedium = 500,
  fontWeightSemiBold = 600,
}

export enum FontFamilies {
  primary = '"Inter", sans-serif',
  secondary = '"Future-Earth", sans-serif',
  tertiary = '"Xolonium", sans-serif',
}

export const getTypographyOptions = (theme: Theme): TypographyOptions => ({
  fontFamily: FontFamilies.primary,

  h1: {
    fontSize: '84px',
    lineHeight: '84px',
    fontWeight: FontWeights.fontWeightRegular,
    fontFamily: FontFamilies.secondary,
    color: COLOR_ORANGE,
    [theme.breakpoints.down('md')]: {
      fontSize: '42px',
      lineHeight: '42px',
    },
  },

  h2: {
    fontSize: '48px',
    lineHeight: '58px',
    fontWeight: FontWeights.fontWeightRegular,
    fontFamily: FontFamilies.tertiary,
    [theme.breakpoints.down('md')]: {
      fontSize: '24px',
      lineHeight: '29px',
    },
  },

  h3: {
    fontSize: '32px',
    lineHeight: '24px',
    color: COLOR_GREEN,
    fontWeight: FontWeights.fontWeightRegular,
    fontFamily: FontFamilies.tertiary,
  },

  h4: {
    fontSize: '20px',
    lineHeight: '24px',
    fontWeight: FontWeights.fontWeightRegular,
    fontFamily: FontFamilies.primary,
  },

  h5: {
    fontSize: '17px',
    lineHeight: '150%',
    fontWeight: FontWeights.fontWeightRegular,
  },

  h6: {
    fontSize: '18px',
    lineHeight: '22px',
    fontWeight: FontWeights.fontWeightRegular,
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      lineHeight: '17px',
    },
  },

  body1: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: FontWeights.fontWeightRegular,
    fontFamily: FontFamilies.primary,
  },

  body2: {
    fontSize: '16px',
    lineHeight: '19px',
    color: COLOR_GREY_400,
    fontWeight: FontWeights.fontWeightRegular,
    fontFamily: FontFamilies.primary,
    [theme.breakpoints.down('md')]: {
      fontSize: '14px',
      lineHeight: '17px',
    },
  },

  subtitle1: {
    fontSize: '14px',
    lineHeight: '20px',
    opacity: 0.4,
    fontWeight: FontWeights.fontWeightRegular,
    fontFamily: FontFamilies.primary,
  },

  subtitle2: {
    fontSize: '12px',
    lineHeight: '15px',
    color: COLOR_GREY_400,
    fontWeight: FontWeights.fontWeightRegular,
    fontFamily: FontFamilies.primary,
  },
});
