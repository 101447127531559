import { FC } from 'react';
import { Grid, Link, Typography } from '@mui/material';
import { FacebookIcon, KumaIcon, TelegramIcon, TwitterIcon, WebIcon } from 'components/Icon/components';

export const Footer: FC = () => {
  return (
    <Grid
      container
      direction={{ xs: 'column-reverse', sm: 'row' }}
      justifyContent={{ xs: 'center', sm: 'space-between' }}
      alignItems="center"
      sx={{ mt: 'auto', pt: { xs: '40px', sm: 3 }, pb: { xs: '13px', sm: '39px' } }}
    >
      <Grid item>
        <Typography variant="body2" sx={{ py: { xs: 2, sm: 0 } }}>
          © {new Date().getFullYear()} dKUMA
        </Typography>
      </Grid>
      <Grid item>
        <Grid container gap={2}>
          <Link href="https://breeder.kumatokens.com" target="_blank" rel="noreferrer noopener">
            <KumaIcon />
          </Link>
          <Link href="https://www.kumatokens.com" target="_blank" rel="noreferrer noopener">
            <WebIcon />
          </Link>
          <Link href="https://t.me/KumaInuInfinite" target="_blank" rel="noreferrer noopener">
            <TelegramIcon />
          </Link>
          <Link href="https://twitter.com/OfficialKumaInu" target="_blank" rel="noreferrer noopener">
            <TwitterIcon />
          </Link>
          <Link
            href="https://www.facebook.com/profile.php?id=100084560958288"
            target="_blank"
            rel="noreferrer noopener"
          >
            <FacebookIcon />
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
};
