import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const TwitterIcon: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 40 40"
    sx={{
      ...sx,
      width: '40px',
      height: '40px',
    }}
  >
    <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" fill="#141823" />
    <path
      d="M15.921 28.9375C24.2208 28.9375 28.7618 22.0595 28.7618 16.0968C28.7618 15.9034 28.7575 15.7058 28.7489 15.5124C29.6322 14.8736 30.3945 14.0823 31 13.1758C30.1773 13.5418 29.3038 13.7808 28.4094 13.8847C29.3512 13.3202 30.0563 12.4334 30.3941 11.3887C29.5082 11.9137 28.5393 12.2841 27.529 12.484C26.8483 11.7607 25.9482 11.2818 24.968 11.1213C23.9879 10.9608 22.9821 11.1277 22.1063 11.5961C21.2304 12.0646 20.5333 12.8085 20.1227 13.7129C19.7121 14.6173 19.6108 15.6318 19.8346 16.5995C18.0406 16.5095 16.2856 16.0435 14.6833 15.2317C13.081 14.4198 11.6672 13.2804 10.5336 11.8871C9.95737 12.8805 9.78105 14.0561 10.0404 15.1748C10.2998 16.2936 10.9755 17.2716 11.93 17.91C11.2134 17.8873 10.5125 17.6944 9.88516 17.3472V17.403C9.88451 18.4455 10.2449 19.4561 10.9051 20.2629C11.5653 21.0698 12.4845 21.6231 13.5066 21.8288C12.8427 22.0104 12.146 22.0369 11.4703 21.9061C11.7587 22.8027 12.3198 23.5869 13.0753 24.1492C13.8308 24.7115 14.743 25.0239 15.6846 25.0429C14.0861 26.2986 12.1113 26.9797 10.0785 26.9765C9.71801 26.9759 9.35787 26.9538 9 26.9103C11.0651 28.2352 13.4674 28.9388 15.921 28.9375Z"
      fill="#3D414D"
    />
    <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="#3D414D" />
  </SvgIcon>
);
