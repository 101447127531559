import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const InfoIcon: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 25 24"
    sx={{
      ...sx,
      width: '25px',
      height: '25px',
    }}
    fill="none"
  >
    <path
      d="M13.1138 16H12.1138V12H11.1138M12.1138 8H12.1238M21.1138 12C21.1138 16.9706 17.0843 21 12.1138 21C7.14321 21 3.11377 16.9706 3.11377 12C3.11377 7.02944 7.14321 3 12.1138 3C17.0843 3 21.1138 7.02944 21.1138 12Z"
      stroke="#3D414D"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
