import { FC } from 'react';
import { Box, styled } from '@mui/material';
import { LoaderIcon } from 'components/Icon/components';
import { flexHelper } from 'utils/flexHelper';

const LoaderIconStyled = styled(LoaderIcon)({
  width: '100px',
  height: 'auto',
  zIndex: 999,
  position: 'relative',
  animation: 'rotate 1s linear infinite',
  '@keyframes rotate': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(360deg)',
    },
  },
});

export const Spinner: FC = () => {
  return (
    <Box
      sx={{
        zIndex: 999,
        height: '100%',
        backgroundColor: 'transparent',
        ...flexHelper(),
      }}
    >
      <LoaderIconStyled />
    </Box>
  );
};
