import type {} from '@mui/lab/themeAugmentation';
import { createTheme } from '@mui/material';
import {
  getMuiButtonDefaultProps,
  getMuiButtonOverrides,
  getMuiIconButtonDefaultProps,
  getMuiIconButtonOverrides,
} from 'theme/Button';
import { getMuiContainerDefaultProps, getMuiContainerOverrides } from 'theme/Container';
import { breakpointOptions, getMuiGridDefaultProps, getMuiGridOverrides } from 'theme/Grid';
import { getTypographyOptions } from 'theme/Typography';

import { getMuiCssBaselineDefaultProps, getMuiCssBaselineOverrides } from './CssBaseLine';
import { getMuiDialogDefaultProps, getMuiDialogOverrides } from './Dialog';
import { getMuiLinkDefaultProps, getMuiLinkOverrides } from './Link';
import { getMuiPopoverDefaultProps, getMuiPopoverOverrides } from './Popover';
import { getMuiRadioDefaultProps, getMuiRadioOverrides } from './Radio';
import { getMuiTextFieldDefaultProps, getMuiTextFieldOverrides } from './TextField';
import { getMuiTooltipDefaultProps, getMuiTooltipOverrides } from './Tooltip';

const themeBase = createTheme({
  breakpoints: breakpointOptions,
});

export const theme = createTheme(themeBase, {
  components: {
    MuiTypography: {
      styleOverrides: getTypographyOptions(themeBase),
    },
    MuiGrid: {
      defaultProps: getMuiGridDefaultProps(),
      styleOverrides: getMuiGridOverrides(),
    },
    MuiContainer: {
      defaultProps: getMuiContainerDefaultProps(),
      styleOverrides: getMuiContainerOverrides(),
    },
    MuiButton: {
      defaultProps: getMuiButtonDefaultProps(),
      styleOverrides: getMuiButtonOverrides(themeBase),
    },
    MuiIconButton: {
      defaultProps: getMuiIconButtonDefaultProps(),
      styleOverrides: getMuiIconButtonOverrides(),
    },
    MuiDialog: {
      defaultProps: getMuiDialogDefaultProps(),
      styleOverrides: getMuiDialogOverrides(),
    },
    MuiCssBaseline: {
      defaultProps: getMuiCssBaselineDefaultProps(),
      styleOverrides: getMuiCssBaselineOverrides(themeBase),
    },
    MuiPopover: {
      defaultProps: getMuiPopoverDefaultProps(),
      styleOverrides: getMuiPopoverOverrides(themeBase),
    },
    MuiTextField: {
      defaultProps: getMuiTextFieldDefaultProps(),
      styleOverrides: getMuiTextFieldOverrides(),
    },
    MuiLink: {
      defaultProps: getMuiLinkDefaultProps(),
      styleOverrides: getMuiLinkOverrides(themeBase),
    },
    MuiRadio: {
      defaultProps: getMuiRadioDefaultProps(),
      styledOverrides: getMuiRadioOverrides(),
    },
    MuiTooltip: {
      defaultProps: getMuiTooltipDefaultProps(),
      styledOverrides: getMuiTooltipOverrides(),
    },
  },
});
