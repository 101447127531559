import { Spinner } from 'components';
import { ErrorIcon, SuccessIcon } from 'components/Icon/components';
import { Modals } from 'types/store/modals';

export const modalData = {
  [Modals.ApprovePending]: {
    title1: 'STEP 1/2',
    title2: 'APPROVE',
    icon: Spinner,
    title3: 'Please press "Approve" button in Metamask extension.',
    title4:
      'ERC-20 tokens are deployed with functionality that allows other smart contracts to move tokens. By approving the smart contracts, it now has permission to execute the peer to peer swapping behavior on your behalf. The Spend Limit permission is the total amount of tokens that are able to move when using MetaMask Swap.',
  },

  [Modals.ApproveRejected]: {
    title1: 'STEP 1/2',
    title2: 'APPROVE',
    icon: ErrorIcon,
    title3: 'You rejected Approve transaction in Metamask.',
    title4:
      'ERC-20 tokens are deployed with functionality that allows other smart contracts to move tokens. By approving the smart contracts, it now has permission to execute the peer to peer swapping behavior on your behalf. The Spend Limit permission is the total amount of tokens that are able to move when using MetaMask Swap.',
  },

  [Modals.StakePending]: {
    title1: 'STEP 2/2',
    title2: 'SEND',
    icon: Spinner,
    title3: 'Please press "Send" button in Metamask extension',
    title4: 'Your tokens will be transferred from your wallet to the dKuma Breeder',
  },

  [Modals.SendPending]: {
    title1: 'STEP 2/2',
    title2: 'SEND',
    icon: Spinner,
    title3: 'Please press "Send" button in Metamask extension',
    title4: 'Your tokens will be transferred from the dKuma Breeder to your wallet.',
  },

  [Modals.SendSuccess]: {
    title1: 'STEP 2/2',
    title2: 'SEND',
    icon: SuccessIcon,
    title3: 'Sent',
    title4: 'It takes some time for transaction to get confirmed.',
  },

  [Modals.SendRejected]: {
    title1: 'STEP 2/2',
    title2: 'SEND',
    icon: ErrorIcon,
    title3: '',
    title4: 'You rejected Send transaction in Metamask. Press Send again to start over or close this window.',
  },
  [Modals.SendError]: {
    title1: 'STEP 2/2',
    title2: 'SEND',
    icon: ErrorIcon,
    title3: '',
    title4: 'Some error occurred. Press Send again to start over or close this window.',
  },
};
