import { select, takeEvery } from 'redux-saga/effects';
import { error, request, success } from 'store/api/actions';
import userSelector from 'store/user/selectors';
import { call, put } from 'typed-redux-saga';
import { UserState } from 'types';

import { updateUserData } from '../actions';
import actionTypes from '../actionTypes';

import { getTokenBalanceSaga } from './getTokenBalance';
import { getUserStakingInfoSaga } from './getUserStakingInfo';

export function* updateUserDataSaga({ type, payload: { web3Provider } }: ReturnType<typeof updateUserData>) {
  yield* put(request(type));
  const { address: userAddress }: UserState = yield select(userSelector.getUser);

  try {
    if (!userAddress.length) {
      return;
    }
    yield call(getTokenBalanceSaga, {
      type: actionTypes.GET_TOKEN_BALANCE,
      payload: { web3Provider },
    });
    yield call(getUserStakingInfoSaga, {
      type: actionTypes.GET_USER_STAKING_INFO,
      payload: { web3Provider },
    });

    yield* put(success(type));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    yield* put(error(type));
  }
}

export default function* listener() {
  yield takeEvery(actionTypes.UPDATE_USER_DATA, updateUserDataSaga);
}
