import { ComponentsOverrides, ComponentsProps } from '@mui/material';

import { BORDER_RADIUS_LARGE, COLOR_BLUE_DARK, COLOR_GREY_300, COLOR_WHITE } from '../variables';

export const getMuiDialogOverrides = (): ComponentsOverrides['MuiDialog'] => ({
  root: {
    background: 'rgba(11, 12, 17, 0.74)',
    backdropFilter: 'blur(18px)',
  },

  paper: {
    background: COLOR_BLUE_DARK,
    width: '100%',
    color: COLOR_WHITE,
    border: `1px solid ${COLOR_GREY_300}`,
    borderRadius: BORDER_RADIUS_LARGE,
    padding: '24px',
    '& svg': {
      color: 'transparent',
    },
  },
});

export const getMuiDialogDefaultProps = (): ComponentsProps['MuiDialog'] => ({});
