import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Chains, UserState } from 'types';

const initialState: UserState = {
  address: '',
  provider: '',
  chainType: 'mainnet',
  network: Chains.Ethereum,
  tokenBalance: '',
  key: '',
  isConnectTipAccepted: false,
  stakingInfo: {
    staked: '0',
    enteredAt: '0',
    totalReward: '0',
    pendingReward: '0',
  },
  currentlyClaimed: '0',
};

export const userReducer = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUserState: (state: UserState, action: PayloadAction<Partial<UserState>>) => ({
      ...state,
      ...action.payload,
    }),
    disconnectWalletState: () => {
      localStorage.removeItem('walletconnect');
      return {
        ...initialState,
        isConnectTipAccepted: true,
      };
    },
  },
});

export const { disconnectWalletState, updateUserState } = userReducer.actions;

export default userReducer.reducer;
