import { call, select } from 'redux-saga/effects';
import { ContractsNames } from 'services/WalletService/config';
import { error, request } from 'store/api/actions';
import { put, takeLatest } from 'typed-redux-saga';
import { UserState } from 'types';
import { StakingAbi } from 'types/contracts';
import { createContract, fromDecimals, getContractDataByItsName } from 'utils';

import userSelector from '../../user/selectors';
import { getStakingData } from '../actions';
import actionTypes from '../actionTypes';
import { updateStakingState } from '../reducer';

import { calculateTvlSaga } from './calculateTvl';

export function* getStakingDataSaga({ type }: ReturnType<typeof getStakingData>) {
  yield put(request(type));

  const { chainType, network }: UserState = yield select(userSelector.getUser);
  const [stakingAbi, stakingAddress] = getContractDataByItsName(ContractsNames.staking, chainType);

  try {
    const stakingContract: StakingAbi = yield createContract(stakingAddress, stakingAbi, network, chainType);

    const totalStaked = yield call(stakingContract.methods.totalStaked().call);
    let harvestPeriod = yield call(stakingContract.methods.HARVEST_PERIOD().call);
    if (typeof harvestPeriod === 'bigint') harvestPeriod = harvestPeriod.toString();
    let poolEndTime = yield call(stakingContract.methods.poolEndTime().call);
    if (typeof poolEndTime === 'bigint') poolEndTime = poolEndTime.toString();

    yield put(updateStakingState({ totalStaked: fromDecimals(totalStaked), harvestPeriod, poolEndTime }));

    yield call(calculateTvlSaga, { type: actionTypes.CALCULATE_TVL, payload: { totalStaked } });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    yield put(error(type));
  }
}

export default function* listener() {
  yield takeLatest(actionTypes.GET_STAKING_DATA, getStakingDataSaga);
}
