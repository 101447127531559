import { FC, useRef } from 'react';
import { Box, Button, Grid, IconButton, Popover, Tooltip, Typography } from '@mui/material';
import { MetamaskLogo, WalletConnectLogo } from 'assets/img';
import { CopyText } from 'components';
import { CloseIcon, WalletIcon } from 'components/Icon/components';
import { useModal } from 'hooks';
import {
  BORDER_RADIUS_DEFAULT,
  COLOR_BLUE_DARK,
  COLOR_GREY_100,
  COLOR_GREY_300,
  COLOR_GREY_500,
  COLOR_ORANGE,
  COLOR_WHITE,
} from 'theme/variables';
import { Chains, WalletProviders } from 'types';
import { flexHelper } from 'utils/flexHelper';

interface ConnectPopoverProps {
  userAddress: string;
  userBalance: string;
  onConnectWallet: (provider: WalletProviders, chain: Chains) => void;
  onDisconnect: () => void;
}

export const ConnectPopover: FC<ConnectPopoverProps> = ({
  userAddress,
  userBalance,
  onConnectWallet,
  onDisconnect,
}) => {
  const popoverRef = useRef(null);
  const [isPopoverVisible, onOpenPopover, onClosePopover] = useModal(false);

  const handleConnect = (provider: WalletProviders, chain: Chains) => {
    onConnectWallet(provider, chain);
    onClosePopover();
  };

  const handleDisconnect = () => {
    onClosePopover();
    onDisconnect();
  };

  return (
    <>
      {userAddress ? (
        <>
          <Grid
            item
            sx={{
              zIndex: isPopoverVisible && 2000,
              borderRadius: BORDER_RADIUS_DEFAULT,
              border: `1px solid ${COLOR_GREY_300}`,
              mt: { xs: '11px', md: 0 },
              pt: '12px',
              pb: { xs: '11px', sm: 2 },
              px: 3,
              maxWidth: { xs: 200, md: 240 },
              overflow: 'hidden',
            }}
          >
            <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
              Wallet balance
            </Typography>
            <Box sx={{ ...flexHelper(), flexWrap: 'nowrap' }}>
              <Tooltip title={userBalance}>
                <Typography variant="h6" noWrap maxWidth={{ xs: 110, md: 120 }}>
                  {userBalance}
                </Typography>
              </Tooltip>
              <Typography variant="h6" color={COLOR_ORANGE} ml={0.5}>
                dKuma
              </Typography>
            </Box>
          </Grid>
          <Grid
            onClick={onOpenPopover}
            item
            flexBasis={{ xs: '100%', md: 0 }}
            sx={{
              zIndex: isPopoverVisible && 2000,
              borderRadius: BORDER_RADIUS_DEFAULT,
              border: `1px solid ${COLOR_GREY_300}`,
              width: 254,
              ml: { xs: 0, md: 2 },
              mt: { xs: '11px', md: 0 },
              '&:hover': {
                cursor: 'pointer',
                borderColor: COLOR_ORANGE,
              },
            }}
          >
            <Box sx={{ pt: '10px', pb: { xs: '10px', sm: 2 }, px: { xs: 2, sm: 3 } }} ref={popoverRef}>
              <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                Wallet address
              </Typography>
              <CopyText text={userAddress} size="sm" />
            </Box>
          </Grid>
        </>
      ) : (
        <Button
          ref={popoverRef}
          onClick={onOpenPopover}
          sx={{
            zIndex: 2000,
            py: { xs: 2.5, sm: 3 },
            svg: { ml: 1, path: { fill: COLOR_ORANGE } },
            '&:hover': { path: { fill: COLOR_BLUE_DARK } },
            width: { xs: '100%', sm: '246px' },
            mt: { xs: '21px', sm: 0 },
            // ml: 'auto',
          }}
        >
          Connect wallet
          <WalletIcon />
        </Button>
      )}
      <Popover
        anchorEl={popoverRef.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isPopoverVisible}
        onClose={onClosePopover}
        sx={{
          '& .MuiPopover-paper': {
            mt: 3,
            p: 2,
            width: 247,
          },
        }}
      >
        <Grid
          container
          justifyContent="space-between"
          sx={{
            borderBottom: `1px solid ${COLOR_GREY_500}`,
            pb: 1,
            mb: 3,
          }}
        >
          <Typography variant="body1">{userAddress ? 'Connect Wallet' : 'Disconnect'}</Typography>
          <IconButton
            sx={{
              svg: { path: { stroke: COLOR_GREY_100 } },
              '&:hover': { svg: { g: { opacity: 1 }, path: { stroke: COLOR_WHITE } } },
            }}
            onClick={onClosePopover}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
        {userAddress ? (
          <>
            <Typography variant="subtitle1" textAlign="center" sx={{ mb: 3 }}>
              Do you really want to disconnect the wallet?
            </Typography>
            <Button fullWidth sx={{ mb: 1.75 }} onClick={handleDisconnect}>
              Disconnect
            </Button>
          </>
        ) : (
          <>
            <Button
              fullWidth
              variant="outlined"
              sx={{ mb: 0.75 }}
              onClick={() => handleConnect(WalletProviders.metamask, Chains.Ethereum)}
            >
              <img src={MetamaskLogo} alt="metamsk" />
            </Button>
            <Button
              fullWidth
              variant="outlined"
              sx={{ mb: 6 }}
              onClick={() => handleConnect(WalletProviders.walletConnect, Chains.Ethereum)}
            >
              <img src={WalletConnectLogo} alt="walletConnect" />
            </Button>
          </>
        )}
      </Popover>
    </>
  );
};
