import { FC, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { useShallowSelector } from 'hooks';
import stakingSelector from 'store/staking/selectors';
import userSelector from 'store/user/selectors';
import { BORDER_RADIUS_LARGE, COLOR_GREEN, COLOR_GREY_300, COLOR_ORANGE } from 'theme/variables';
import { StakingState, State, UserState } from 'types';
import { getCurrentTimestampInSeconds } from 'utils';
import { flexHelper } from 'utils/flexHelper';

import { StakeUnstake, StakingInfo, StakingStats } from '../../container';

export const Home: FC = () => {
  const { harvestPeriod } = useShallowSelector<State, StakingState>(stakingSelector.getStaking);
  const { stakingInfo } = useShallowSelector<State, UserState>(userSelector.getUser);

  const canUserClaim = useMemo(
    () => getCurrentTimestampInSeconds() > +stakingInfo.enteredAt + +harvestPeriod,
    [harvestPeriod, stakingInfo.enteredAt],
  );

  return (
    <Box sx={{ width: '100%', ...flexHelper('space-between', 'stretch') }}>
      <Box
        sx={{
          border: `1px solid ${COLOR_GREY_300}`,
          borderRadius: BORDER_RADIUS_LARGE,
          px: 3,
          py: '28px',
          maxWidth: 347,
          width: '100%',
          textAlign: 'center',
          display: { xs: 'none', xl: 'block' },
        }}
      >
        <Typography variant="h3" sx={{ mb: '30px', color: canUserClaim ? COLOR_GREEN : COLOR_ORANGE }}>
          Staking
        </Typography>
        <Box
          sx={{
            py: '30px',
            mb: '28px',
            borderBottom: '1px solid rgba(123,123,123,0.2)',
            borderTop: '1px solid rgba(123,123,123,0.2)',
          }}
        >
          <StakingStats />
        </Box>
        <StakeUnstake />
      </Box>
      <StakingInfo />
    </Box>
  );
};
