import { createAction } from '@reduxjs/toolkit';
import { CalculateTvlReq, RequestWithWeb3Provider, StakeReq, UnstakeReq } from 'types/requests';

import actionTypes from './actionTypes';

export const getStakingData = createAction(actionTypes.GET_STAKING_DATA);
export const stake = createAction<StakeReq>(actionTypes.STAKE);
export const unstake = createAction<UnstakeReq>(actionTypes.UNSTAKE);
export const claim = createAction<RequestWithWeb3Provider>(actionTypes.CLAIM);
export const calculateTvl = createAction<CalculateTvlReq>(actionTypes.CALCULATE_TVL);
