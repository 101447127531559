import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material';
import { LockIcon } from 'assets/img';
import { FontFamilies, FontWeights } from 'theme/Typography';
import {
  BORDER_RADIUS_DEFAULT,
  BORDER_RADIUS_SMALL,
  COLOR_BLUE_DARK,
  COLOR_GREEN,
  COLOR_GREEN_DARK,
  COLOR_GREY_300,
  COLOR_GREY_400,
  COLOR_ORANGE,
  COLOR_ORANGE_DARK,
  COLOR_WHITE,
  TRANSITION_DEFAULT_TIME,
} from 'theme/variables';
import { flexHelper } from 'utils/flexHelper';

export const getMuiButtonOverrides = (theme: Theme): ComponentsOverrides['MuiButton'] => ({
  root: {
    textAlign: 'center',
    borderRadius: BORDER_RADIUS_DEFAULT,
    transition: TRANSITION_DEFAULT_TIME,
    textTransform: 'uppercase',
    fontWeight: FontWeights.fontWeightSemiBold,
    padding: theme.spacing(1.5, 2),
    // minWidth: '215px',
    fontSize: '16px',
    lineHeight: '19px',
    fontFamily: FontFamilies.primary,
    boxShadow: 'none',
    border: '1px solid transparent',
    backgroundColor: 'transparent',
    color: 'transparent',

    '&:hover': {
      boxShadow: 'none',
    },

    '&:active': {
      boxShadow: 'none',
    },

    '&:disabled': {
      background: 'transparent',
      color: COLOR_GREY_400,
      borderColor: COLOR_GREY_300,
      ...flexHelper('center', 'center'),
      '&:before': {
        content: '""',
        backgroundImage: `url(${LockIcon})`,
        width: 20,
        height: 20,
        marginRight: '10px',
      },
    },
  },

  sizeSmall: {
    borderRadius: BORDER_RADIUS_SMALL,
    padding: theme.spacing(0.5, 1),
    minWidth: '48px',
  },

  outlined: {
    color: COLOR_WHITE,
    borderColor: COLOR_GREY_300,

    '&:hover': {
      borderColor: COLOR_ORANGE,
    },

    '&:active': {
      borderColor: COLOR_ORANGE,
    },
  },

  contained: {
    color: COLOR_ORANGE,
    backgroundColor: COLOR_ORANGE_DARK,
    borderColor: COLOR_ORANGE,

    '&:hover': {
      backgroundColor: COLOR_ORANGE,
      color: COLOR_BLUE_DARK,
    },

    '&:active': {
      backgroundColor: COLOR_ORANGE,
      color: COLOR_BLUE_DARK,
    },
  },

  containedSecondary: {
    color: COLOR_GREEN,
    backgroundColor: COLOR_GREEN_DARK,
    borderColor: COLOR_GREEN,

    '&:hover': {
      backgroundColor: COLOR_GREEN,
      color: COLOR_BLUE_DARK,
    },

    '&:active': {
      backgroundColor: COLOR_GREEN,
      color: COLOR_BLUE_DARK,
    },
  },
  startIcon: {
    marginRight: '25px',
  },
});

export const getMuiButtonDefaultProps = (): ComponentsProps['MuiButton'] => ({
  disableElevation: false,
  disableFocusRipple: true,
  disableRipple: true,
  variant: 'contained',
});
