import { FC } from 'react';
import { Button, Grid, styled, Typography } from '@mui/material';
import { FontFamilies, FontWeights } from 'theme/Typography';
import { Chains, INotifyModalProps, WalletProviders } from 'types';

interface IConnectWalletModalProps extends INotifyModalProps {
  onConnectWallet: (provider: WalletProviders, chain: Chains) => void;
}

const WalletButton = styled(Button)({
  width: '100%',
  maxWidth: '100%',
  height: '76px',
  background: 'transparent',
  // color: COLOR_TEXT_BLACK,
  fontFamily: FontFamilies.primary,
  fontSize: '22px',

  '&:hover': {
    background: 'transparent',
  },
});

export const ConnectWalletModal: FC<IConnectWalletModalProps> = ({ onConnectWallet, closeModal, currData }) => {
  const handleConnect = (provider: WalletProviders, chain: Chains) => {
    onConnectWallet(provider, chain);
    closeModal();
  };

  return (
    <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" rowGap={2}>
      <Typography variant="subtitle1">{currData?.subtitle}</Typography>
      <Grid item container xs={12}>
        <WalletButton
          sx={{
            fontWeight: FontWeights.fontWeightMedium,
            letterSpacing: '3.5px',
          }}
          onClick={() => handleConnect(WalletProviders.metamask, Chains.Ethereum)}
        >
          MetaMask
        </WalletButton>
      </Grid>
      <Grid item container xs={12}>
        <WalletButton
          sx={{
            textTransform: 'none',
          }}
          onClick={() => handleConnect(WalletProviders.walletConnect, Chains.Ethereum)}
        >
          WalletConnect
        </WalletButton>
      </Grid>
    </Grid>
  );
};
