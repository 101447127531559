import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const ShareTwitterIcon: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 24 25"
    sx={{
      width: '24px',
      height: '25px',
      ...sx,
    }}
  >
    <g clipPath="url(#clip0_4_7108)">
      <path
        d="M7.55016 21.9501C16.6045 21.9501 21.5583 14.4468 21.5583 7.94193C21.5583 7.731 21.5536 7.51537 21.5442 7.30443C22.5079 6.60753 23.3395 5.74433 24 4.75537C23.1025 5.15467 22.1496 5.41546 21.1739 5.52881C22.2013 4.91298 22.9705 3.94555 23.3391 2.80584C22.3726 3.37863 21.3156 3.78269 20.2134 4.00068C19.4708 3.21163 18.489 2.68919 17.4197 2.51412C16.3504 2.33906 15.2532 2.52112 14.2977 3.03217C13.3423 3.54322 12.5818 4.35478 12.1338 5.34138C11.6859 6.32799 11.5754 7.43469 11.8195 8.49037C9.86249 8.39216 7.94794 7.88378 6.19998 6.99817C4.45203 6.11257 2.90969 4.86951 1.67297 3.34959C1.0444 4.43332 0.852057 5.71572 1.13503 6.93616C1.418 8.15661 2.15506 9.22352 3.19641 9.92006C2.41463 9.89524 1.64998 9.68475 0.965625 9.306V9.36693C0.964925 10.5042 1.3581 11.6067 2.07831 12.4868C2.79852 13.367 3.80132 13.9706 4.91625 14.1951C4.19206 14.3932 3.43198 14.4221 2.69484 14.2794C3.00945 15.2575 3.62157 16.113 4.44577 16.7264C5.26997 17.3398 6.26512 17.6807 7.29234 17.7013C5.54842 19.0712 3.39417 19.8142 1.17656 19.8107C0.783287 19.8101 0.390399 19.786 0 19.7385C2.25286 21.1838 4.87353 21.9515 7.55016 21.9501Z"
        fill="#FF8400"
      />
    </g>
    <defs>
      <clipPath id="clip0_4_7108">
        <rect width="24" height="24" fill="white" transform="translate(0 0.200073)" />
      </clipPath>
    </defs>
  </SvgIcon>
);
