const APPROVE = 'APPROVE' as const;
const GET_TOKEN_BALANCE = 'GET_TOKEN_BALANCE' as const;
const UPDATE_USER_DATA = 'UPDATE_USER_DATA' as const;
const GET_USER_STAKING_INFO = 'GET_USER_STAKING_INFO' as const;

export default {
  APPROVE,
  GET_TOKEN_BALANCE,
  UPDATE_USER_DATA,
  GET_USER_STAKING_INFO,
};
