export const termsItems = [
  {
    id: 1,
    title: 'Privacy Policy',
    link: 'https://google.com',
  },
  {
    id: 2,
    title: 'Terms of Service',
    link: 'https://google.com',
  },
];
