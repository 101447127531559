import { useCallback, useEffect, useRef, useState } from 'react';
import { DateLike, TNullable } from 'types';

interface ITimeLeft {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

const MILLISECONDS = 1;
const SECONDS = 1000 * MILLISECONDS;
const MINUTE = 60 * SECONDS;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;
/**
 * Calculates time left from endTime to now
 * @param endTime
 * @returns {
 *     days: number;
 *     hours: number;
 *     minutes: number;
 *     seconds: number;
 * } | null
 */
const useTimeLeft = (endTime: DateLike, onTimerOut?: () => void): TNullable<ITimeLeft> => {
  const timer = useRef<NodeJS.Timer | null>(null);
  const [timeLeft, setTimeLeft] = useState<TNullable<ITimeLeft>>(null);

  const clearTimer = useCallback(() => {
    if (timer.current) {
      clearInterval(timer.current);
      timer.current = null;
      setTimeLeft(null);
      setTimeout(() => {
        onTimerOut?.();
      }, 1000);
    }
  }, [timer, onTimerOut]);

  const calculateTimeLeft = useCallback(
    (time: number) => {
      const difference = time - Date.now();
      if (difference > 0) {
        let timeTracker = difference;
        const days = Math.floor(timeTracker / DAY);
        timeTracker -= days * DAY;
        const hours = Math.floor(timeTracker / HOUR);
        timeTracker -= hours * HOUR;
        const minutes = Math.floor(timeTracker / MINUTE);
        timeTracker -= minutes * MINUTE;
        const seconds = Math.floor(timeTracker / SECONDS);
        setTimeLeft({
          days,
          hours,
          minutes,
          seconds,
        });
      } else {
        clearTimer();
      }
    },
    [clearTimer],
  );

  useEffect(() => {
    if (!timer.current && +endTime) {
      timer.current = setInterval(() => {
        calculateTimeLeft(+endTime);
      }, 1000);
    }

    return () => clearTimer();
  }, [calculateTimeLeft, endTime, clearTimer]);

  return timeLeft;
};

export default useTimeLeft;
