import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const ArrowIcon: FC<SvgIconProps> = ({ sx, ...props }) => (
  <SvgIcon
    {...props}
    viewBox="0 0 178 178"
    sx={{
      ...sx,
      width: '178px',
      height: '178px',
    }}
  >
    <g clipPath="url(#clip0_11_10618)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.807 138.465C61.109 135.769 72.378 127.684 81.5008 117.044C95.2775 100.975 104.165 79.0757 107.727 60.8594C109.966 49.4108 107.654 40.9168 107.372 29.3111C107.36 28.841 107.732 28.4507 108.202 28.4395C108.67 28.4272 109.062 28.7999 109.072 29.2688C109.358 41.0104 111.662 49.604 109.397 61.1867C105.786 79.6568 96.7616 101.856 82.7924 118.151C73.4183 129.085 61.8156 137.361 48.1454 140.133C47.6849 140.227 47.2362 139.931 47.1427 139.47C47.0491 139.009 47.3464 138.559 47.807 138.465Z"
        fill="#3D414D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M109.482 26.738C109.485 26.7796 109.501 27.0566 109.539 27.2868C109.691 28.2424 110.111 29.8901 110.763 31.9375C112.959 38.8029 117.781 50.1387 124.435 54.8383C124.819 55.1095 124.909 55.6401 124.638 56.024C124.367 56.4078 123.837 56.501 123.453 56.2298C116.529 51.3399 111.427 39.5987 109.143 32.4549C108.838 31.4978 108.58 30.6225 108.377 29.8582C107.609 32.1493 106.902 34.4556 106.22 36.7758C104.257 43.4561 101.175 48.8284 96.5274 54.0827C96.2178 54.4344 95.6782 54.4674 95.3275 54.1559C94.9758 53.8463 94.9428 53.3067 95.2543 52.956C99.7264 47.898 102.697 42.7267 104.587 36.297C105.48 33.2651 106.416 30.2531 107.465 27.272C107.57 26.9753 107.849 26.2073 107.906 26.1058C108.225 25.5267 108.771 25.6538 108.891 25.6879C108.948 25.7038 109.652 25.9442 109.482 26.738ZM107.807 26.4403C107.812 26.4185 107.815 26.3956 107.821 26.3719C107.815 26.3908 107.812 26.4137 107.807 26.4403Z"
        fill="#3D414D"
      />
    </g>
    <defs>
      <clipPath id="clip0_11_10618">
        <rect width="130" height="130" fill="white" transform="matrix(0.866025 0.5 0.5 -0.866025 0 112.583)" />
      </clipPath>
    </defs>
  </SvgIcon>
);
